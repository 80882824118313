import { Routes } from '@angular/router';
import { OnboardComponent } from './pages/onboard/onboard.component';
import { ProductListComponent } from './pages/product-list/product-list.component';
import { OrderCheckoutComponent } from './pages/order-checkout/order-checkout.component';
import { OrderStatusComponent } from './pages/order-status/order-status.component';
import { CustomerProfileComponent } from './pages/customer-profile/customer-profile.component';
import { OrderComponent } from './pages/order/order.component';
import { QuickbookConnectComponent } from './admin/quickbook-connect/quickbook-connect.component';
import { QuickbookCallbackComponent } from './admin/quickbook-callback/quickbook-callback.component';
import { AdminOrderListComponent } from './admin/admin-order-list/admin-order-list.component';

export const routes: Routes = [
  {
    path: 'onboard',
    children: [
      {
        path: '',
        component: OnboardComponent
      },
    ]
  },
  {
    path: 'customer',
    children: [
      {
        path: 'profile',
        component: CustomerProfileComponent
      },
    ]
  },
  {
    path: 'products',
    component: ProductListComponent
  },
  {
    path: 'order',
    children: [
      {
        path: '',
        component: OrderComponent
      },
      {
        path: 'checkout',
        component: OrderCheckoutComponent
      },
      {
        path: 'status',
        component: OrderStatusComponent
      }
    ]
  },
  {
    path: 'admin',
    children: [
      {
        path: 'login',
        component: QuickbookConnectComponent
      },
      {
        path: 'callback',
        component: QuickbookCallbackComponent
      },
      {
        path: 'orders',
        component: AdminOrderListComponent,
      },
    ]
  }
];