import { __spreadArray as o, __read as t } from "tslib";
var n;
!function (o) {
  o[o.DEBUG = 1] = "DEBUG", o[o.INFO = 2] = "INFO", o[o.WARN = 3] = "WARN", o[o.ERROR = 4] = "ERROR";
}(n || (n = {}));
var r = function () {
    function r(o) {
      void 0 === o && (o = n.INFO), this.logLevel = o, this._debug = console.debug, this._info = console.info, this._warn = console.warn, this._error = console.error;
    }
    return r.prototype.debug = function () {
      for (var r = [], i = 0; i < arguments.length; i++) r[i] = arguments[i];
      this.logLevel <= n.DEBUG && (r.unshift("[DEBUG]"), this._debug.apply(this, o([], t(r), !1)));
    }, r.prototype.info = function () {
      for (var r = [], i = 0; i < arguments.length; i++) r[i] = arguments[i];
      this.logLevel <= n.INFO && (r.unshift("[INFO]"), this._info.apply(this, o([], t(r), !1)));
    }, r.prototype.warn = function () {
      for (var r = [], i = 0; i < arguments.length; i++) r[i] = arguments[i];
      this.logLevel <= n.WARN && (r.unshift("[WARN]"), this._warn.apply(this, o([], t(r), !1)));
    }, r.prototype.error = function () {
      for (var r = [], i = 0; i < arguments.length; i++) r[i] = arguments[i];
      this.logLevel <= n.ERROR && (r.unshift("[ERROR]"), this._error.apply(this, o([], t(r), !1)));
    }, r;
  }(),
  i = new r(Number("3"));
export { n as LogLevel, r as Logger, i as logger };