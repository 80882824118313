import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Router } from '@angular/router';
import { OrderService } from '../../services/order.service';
import { LiffService } from '../../services/liff.service';
import { ReplaySubject, takeUntil } from 'rxjs';
import { Order } from '../../interfaces/order';

@Component({
  selector: 'app-order',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatDividerModule
  ],
  templateUrl: './order.component.html',
  styleUrl: './order.component.scss'
})
export class OrderComponent implements OnInit {
  orders : Order[] = [];

  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    private router: Router,
    private liffService: LiffService,
    private orderService: OrderService,
  ) {}

  ngOnInit(): void {
    this.orderService.getAll(this.liffService.accessToken!)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (orders: Order[]) => {
          this.orders = orders;
        },
        error: (e) => {
        },
        complete: () => {
        }
      });
  }

  viewOrderDetail(order: Order): void {
    this.router.navigate([`/order/status`], { queryParams: {id: order.id} });
  }
}
