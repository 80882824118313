@if (profile && customer) {
    <mat-toolbar color="primary">
        <span>Profile</span>
    </mat-toolbar>
    
    <div class="container">
        <mat-card>
            <div class="profile-picture-container">
                <div class="profile-picture" [ngStyle]="{'background-image': 'url(' + customer.profilePicture + ')'}"></div>
            </div>
    
            <div class="label-container">
                <mat-label>Name</mat-label>
                <mat-label class="value">{{ customer.name }}</mat-label>
            </div>
        
            <div class="label-container">
                <mat-label>Phone</mat-label>
                <mat-label class="value">{{ customer.phoneNo }}</mat-label>
            </div>

            <div class="label-container">
                <mat-label>Restaurant Name</mat-label>
                <mat-label class="value">{{ customer.restaurant.name }}</mat-label>
            </div>
        </mat-card>
    </div>
    }