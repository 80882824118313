import { __values as n, __spreadArray as t, __read as r, __awaiter as e, __generator as i } from "tslib";
var s = function () {
    var e = this;
    this.type = "sync", this.fns = new Set(), this.on = function (n) {
      e.fns.add(n);
    }, this.call = function () {
      for (var i, s, a = [], o = 0; o < arguments.length; o++) a[o] = arguments[o];
      try {
        for (var l = n(e.fns), c = l.next(); !c.done; c = l.next()) {
          var f = c.value;
          f.apply(void 0, t([], r(a), !1));
        }
      } catch (u) {
        i = {
          error: u
        };
      } finally {
        try {
          c && !c.done && (s = l.return) && s.call(l);
        } finally {
          if (i) throw i.error;
        }
      }
    };
  },
  a = function () {
    var s = this;
    this.type = "async", this.fns = new Set(), this.on = function (n) {
      s.fns.add(n);
    }, this.call = function () {
      for (var a = [], o = 0; o < arguments.length; o++) a[o] = arguments[o];
      return e(s, void 0, void 0, function () {
        var e, s, o, l, c, f;
        return i(this, function (i) {
          switch (i.label) {
            case 0:
              e = [];
              try {
                for (s = n(this.fns), o = s.next(); !o.done; o = s.next()) l = o.value, e.push(l.apply(void 0, t([], r(a), !1)));
              } catch (u) {
                c = {
                  error: u
                };
              } finally {
                try {
                  o && !o.done && (f = s.return) && f.call(s);
                } finally {
                  if (c) throw c.error;
                }
              }
              return [4, Promise.all(e)];
            case 1:
              return i.sent(), [2];
          }
        });
      });
    };
  },
  o = function () {
    var s = this;
    this.type = "asyncSeries", this.fns = new Set(), this.on = function (n) {
      s.fns.add(n);
    }, this.call = function () {
      for (var a = [], o = 0; o < arguments.length; o++) a[o] = arguments[o];
      return e(s, void 0, void 0, function () {
        var e, s, o, l, c;
        return i(this, function (i) {
          switch (i.label) {
            case 0:
              i.trys.push([0, 5, 6, 7]), e = n(this.fns), s = e.next(), i.label = 1;
            case 1:
              return s.done ? [3, 4] : [4, s.value.apply(void 0, t([], r(a), !1))];
            case 2:
              i.sent(), i.label = 3;
            case 3:
              return s = e.next(), [3, 1];
            case 4:
              return [3, 7];
            case 5:
              return o = i.sent(), l = {
                error: o
              }, [3, 7];
            case 6:
              try {
                s && !s.done && (c = e.return) && c.call(e);
              } finally {
                if (l) throw l.error;
              }
              return [7];
            case 7:
              return [2];
          }
        });
      });
    };
  };
export { a as AsyncHook, o as AsyncSeriesHook, s as SyncHook };