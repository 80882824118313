import { __extends as n, __awaiter as t, __generator as e } from "tslib";
import { LiffModule as i } from "@liff/use";
import { INIT_FAILED as r } from "@liff/consts";
import { createLiffError as o } from "@liff/util";
import { fetch as s } from "@liff/server-api";
var a,
  u = "undefined" == typeof navigator ? "en" : null !== (a = navigator.language) && void 0 !== a ? a : "en",
  c = null;
function l(n) {
  return t(this, void 0, void 0, function () {
    return e(this, function (t) {
      switch (t.label) {
        case 0:
          return u = n, [4, f()];
        case 1:
          return t.sent(), [2];
      }
    });
  });
}
function f() {
  return t(this, void 0, void 0, function () {
    var n, t;
    return e(this, function (e) {
      switch (e.label) {
        case 0:
          return [4, s("".concat("https://liffsdk.line-scdn.net/xlt/manifest.json"), {
            method: "GET",
            headers: {
              Accept: "application/json"
            }
          })];
        case 1:
          return n = e.sent(), t = "".concat(u), !n.languages[t] && u.includes("-") && (t = u.split("-")[0]), n.languages[t] || (t = "en"), [4, s("".concat("https://liffsdk.line-scdn.net/xlt", "/").concat(n.languages[t]), {
            method: "GET",
            headers: {
              Accept: "application/json"
            }
          })];
        case 2:
          return c = e.sent(), [2];
      }
    });
  });
}
function p(n) {
  if (null === c) throw o(r, "please call xlt after liff.init");
  return c[n];
}
var d = function (i) {
    function r() {
      return null !== i && i.apply(this, arguments) || this;
    }
    return n(r, i), Object.defineProperty(r.prototype, "name", {
      get: function () {
        return "i18n";
      },
      enumerable: !1,
      configurable: !0
    }), r.prototype.install = function (n) {
      return n.internalHooks.init.beforeFinished(this.beforeInitFinished.bind(this)), {
        setLang: l
      };
    }, r.prototype.beforeInitFinished = function () {
      return t(this, void 0, void 0, function () {
        return e(this, function (n) {
          switch (n.label) {
            case 0:
              return [4, f()];
            case 1:
              return n.sent(), [2];
          }
        });
      });
    }, r;
  }(i),
  h = new d();
export { d as I18nModule, h as module, p as t };