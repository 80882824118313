import { logger as n } from "@liff/logger";
import { __extends as r, __read as t, __makeTemplateObject as e } from "tslib";
import { PERMANENT_LINK_ORIGIN as o, PERMANENT_LINK_ORIGIN_MINI as i, CREDENTIAL_KEYS as a } from "@liff/consts";
function c(n) {
  return window.atob(n.replace(/-/g, "+").replace(/_/g, "/"));
}
var u = {
  decode: c,
  encode: function (n) {
    return window.btoa(n).replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
  },
  decodeUnicode: function (n) {
    var r = c(n).split("").map(function (n) {
      return "%" + ("00" + n.charCodeAt(0).toString(16)).slice(-2);
    }).join("");
    return decodeURIComponent(r);
  }
};
function f(n, r) {
  if (n === r) return 0;
  for (var t = n.split("."), e = r.split("."), o = Math.max(t.length, e.length), i = 0; i < o; i++) {
    t[i] || (t[i] = "0"), e[i] || (e[i] = "0");
    var a = parseInt(t[i]) - parseInt(e[i]);
    if (0 !== a) return a > 0 ? 1 : -1;
  }
  return 0;
}
function s(r) {
  var t = r.replace("#", "").match(/.{2}/g) || [];
  if (4 !== t.length) return n.warn("convertArgbToRgba: Received invalid ARGB color"), "";
  var e = function (n) {
      var r = p(n);
      return Math.round(r / 255 * 100) / 100;
    }(t[0]),
    o = p(t[1]),
    i = p(t[2]),
    a = p(t[3]);
  return "rgba(".concat(o, ", ").concat(i, ", ").concat(a, ", ").concat(e, ")");
}
function p(n) {
  return parseInt(n, 16);
}
function d(r) {
  var t = r.replace("#", "").match(/.{2}/g) || [];
  if (3 !== t.length) return n.warn("convertArgbToRgba: Received invalid hex color"), "";
  var e = p(t[0]),
    o = p(t[1]),
    i = p(t[2]);
  return "".concat(e, ", ").concat(o, ", ").concat(i);
}
function l(n) {
  for (var r = n.length, t = new ArrayBuffer(r), e = new Uint8Array(t), o = 0; o < r; o++) e[o] = n.charCodeAt(o);
  return t;
}
var v = {
    get: function (n) {
      var r = new RegExp("(?:(?:^|.*;\\s*)".concat(n, "\\s*\\=\\s*([^;]*).*$)|^.*$"));
      return document.cookie.replace(r, "$1");
    },
    set: function (r, t, e) {
      var o = r + "=" + t;
      if (e) for (var i in e) {
        var a = e[i] ? "=".concat(e[i]) : "";
        o += "; ".concat(i).concat(a);
      }
      n.debug("set cookie", o), document.cookie = o;
    },
    remove: function (n, r) {
      var t = "".concat(n, "=; expires=Thu, 01 Jan 1970 00:00:00 GMT");
      if (r) for (var e in r) t += "; ".concat(e, "=").concat(r[e]);
      document.cookie = t;
    }
  },
  g = new Set(["400", "401", "403", "404", "429", "500"]),
  h = function (n) {
    function t(r, t, e) {
      var o = n.call(this, t, e) || this;
      return o.code = r, o;
    }
    return r(t, n), t;
  }(Error);
function w(n, r, t) {
  return (null == t ? void 0 : t.cause) && console.error("This is the cause of LiffError described below.", t.cause), new h(n, r || "", t || {});
}
function m(n) {
  var r = n.match(/([^-]+)-[^-]+/);
  return r && r[1];
}
function y(n) {
  var r = "";
  return n.replace(/\r|\n/g, "").replace(/([\da-fA-F]{2}) ?/g, "0x$1 ").replace(/ +$/, "").split(" ").forEach(function (n) {
    r += String.fromCharCode(parseInt(n));
  }), window.btoa(r);
}
var b = function () {
  function n() {
    this.map = {};
  }
  return n.prototype.clear = function () {
    this.map = {};
  }, n.prototype.getItem = function (n) {
    var r = this.map[n];
    return void 0 === r ? null : r;
  }, n.prototype.setItem = function (n, r) {
    this.map[n] = r;
  }, n.prototype.removeItem = function (n) {
    delete this.map[n];
  }, n.prototype.key = function (n) {
    var r = Object.keys(this.map)[n];
    return void 0 === r ? null : r;
  }, Object.defineProperty(n.prototype, "length", {
    get: function () {
      return Object.keys(this.map).length;
    },
    enumerable: !1,
    configurable: !0
  }), n;
}();
var R,
  A = {
    parse: function (n) {
      return n.replace(/^\?/, "").replace(/^#\/?/, "").split(/&+/).filter(function (n) {
        return n.length > 0;
      }).reduce(function (n, r) {
        var e = t(r.split("=").map(decodeURIComponent), 2),
          o = e[0],
          i = e[1],
          a = n[o];
        return Array.isArray(a) ? a.push(i) : Object.prototype.hasOwnProperty.call(n, o) ? n[o] = [a, i] : n[o] = i, n;
      }, {});
    },
    stringify: function (n) {
      return Object.keys(n).map(function (r) {
        var t = n[r],
          e = function (n) {
            return void 0 !== n ? "".concat(encodeURIComponent(r), "=").concat(encodeURIComponent(n)) : encodeURIComponent(r);
          };
        return Array.isArray(t) ? t.map(function (n) {
          return e(n);
        }).join("&") : e(t);
      }).join("&");
    }
  },
  I = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
function U() {
  return window.crypto.getRandomValues(new Uint32Array(1))[0] / 4294967296;
}
function x(n) {
  for (var r = "", t = 0; t < n; t++) r += I[Math.floor(U() * I.length)];
  return r;
}
function C(n) {
  var r = new URL(n),
    t = r.hash.slice(1).split("&").filter(function (n) {
      return !a.some(function (r) {
        return n.startsWith("".concat(r, "="));
      });
    }).join("&");
  return r.hash = t, r.toString();
}
function L(n) {
  var r = new URL(n);
  return r.toString().replace(new RegExp(String.raw(O || (O = e(["^", ""], ["^", ""])), r.origin)), "");
}
var j = function (n) {
  var r = L(C(n));
  window.history.replaceState(history.state, "", r);
};
function E(n, r) {
  if (!n) throw new Error("addParamsToUrl: invalid URL");
  var e = new URL(n);
  return Object.entries(r).forEach(function (n) {
    var r = t(n, 2),
      o = r[0],
      i = r[1];
    e.searchParams.set(o, i);
  }), e.toString();
}
var O,
  S = ((R = {})[o] = function () {
    var n = $(o);
    return new RegExp("^".concat(n, "(\\d+-\\w+)"));
  }, R[i] = function () {
    var n = $(i);
    return new RegExp("^".concat(n, "((\\d+-\\w+)|(\\w+$))"));
  }, R);
function k(n) {
  for (var r in S) {
    var t = n.match(S[r]());
    if (t) return t[1];
  }
  return null;
}
function $(n) {
  return n.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}
function T(n) {
  var r = n.match(/^(https?:\/\/.*?)\//);
  return r && r[1] || "";
}
function F(n) {
  return n.startsWith("line:");
}
function M(n) {
  void 0 === n && (n = window.navigator.userAgent);
  var r = n.toLowerCase();
  return -1 !== r.indexOf("msie") || -1 !== r.indexOf("trident");
}
function P(n) {
  return void 0 === n && (n = window.navigator.userAgent), /ipad/.test(n.toLowerCase());
}
function W(n) {
  return void 0 === n && (n = window.navigator.userAgent), /Line\/\d+\.\d+\.\d+/.test(n);
}
function B(n) {
  return void 0 === n && (n = window.navigator.userAgent), /Line\/\d+\.\d+\.\d+ LIFF/.test(n);
}
function G(n) {
  return void 0 === n && (n = window.navigator.userAgent), /LIFF\/SubWindow/.test(n);
}
var J = function () {
  return "undefined" == typeof window;
};
export { g as HTTPStatusCodes, b as InMemoryStorage, h as LiffError, E as addParamsToUrl, u as base64Url, f as compareVersion, s as convertArgbToRgba, l as convertArrayBuffer, d as convertHexToRgb, v as cookie, w as createLiffError, m as extractChannelIdFromLiffId, k as extractLiffId, T as getOriginOfUrl, U as getRandomValue, y as hexToBase64, M as isIE, P as isIpad, B as isLIFFBrowser, W as isLINEBrowser, F as isLineCustomUrlScheme, J as isNonBrowserEnvironment, G as isSubWindow, A as qs, x as randomAlphaNumericString, C as removeCredential, L as removeOrigin, j as replaceUrlCredentialRemoved };