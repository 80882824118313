import { Component, OnInit } from '@angular/core';
import { AdminOrderService } from '../../services/admin/admin-order.service';
import { QuickbookService } from '../../services/admin/quickbook.service';
import { ReplaySubject, takeUntil } from 'rxjs';
import { Order } from '../../interfaces/order';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';

@Component({
  selector: 'app-admin-order-list',
  standalone: true,
  imports: [ 
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatDividerModule
  ],
  templateUrl: './admin-order-list.component.html',
  styleUrl: './admin-order-list.component.scss'
})
export class AdminOrderListComponent implements OnInit {
  orders: Order[] = [];
  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    private orderService: AdminOrderService,
    private quickbook: QuickbookService
  ) {}

  ngOnInit(): void {
    if(this.quickbook.token) {
      this.orderService.getAll(this.quickbook.token.accessToken)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (orders: Order[]) => {
            this.orders = orders;
          },
          error: () => {
            
          }
        });
    }
  }

  createInvoice(orderId: number) {
    if(this.quickbook.token) {
      this.orderService.createInvoice(this.quickbook.token.accessToken, orderId)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (InvoicedOrder: Order) => {
            let pickOrder = this.orders.find(o => o.id === orderId);
            if(pickOrder) {
              pickOrder.quickbookInvoiceRef = InvoicedOrder.quickbookInvoiceRef;
            }
          },
          error: () => {

          }
        });
    }
  }
  
}
