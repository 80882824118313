import { __extends as t } from "tslib";
import { LiffModule as n } from "@liff/use";
function r() {
  return navigator.language;
}
var e = function (n) {
  function e() {
    return null !== n && n.apply(this, arguments) || this;
  }
  return t(e, n), Object.defineProperty(e.prototype, "name", {
    get: function () {
      return "getLanguage";
    },
    enumerable: !1,
    configurable: !0
  }), e.prototype.install = function () {
    return function () {
      return r();
    };
  }, e;
}(n);
export { e as GetLanguageModule, r as getLanguage };