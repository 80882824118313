import { __extends as i } from "tslib";
import { INVALID_CONFIG as o } from "@liff/consts";
import { logger as r } from "@liff/logger";
import { hexToBase64 as e, randomAlphaNumericString as t, createLiffError as f, qs as n } from "@liff/util";
import { getConfig as l, setLoginTmp as p } from "@liff/store";
import { getEndPoint as c } from "@liff/server-api";
import { LiffModule as m } from "@liff/use";
import { getVersion as s } from "@liff/get-version";
import { isSubWindow as d } from "@liff/is-sub-window";
import { isInClient as a } from "@liff/is-in-client";
import u from "tiny-sha256";
import { SyncHook as g } from "@liff/hooks";
import { getMessageBus as h } from "@liff/sub-window";
var _ = function (i) {
    var m,
      g = t(43),
      _ = e(u(g)).replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, ""),
      b = l();
    if (!b || !b.liffId) throw f(o, "You need to define `liffId` for liff.login()");
    var v = {
      app_id: b.liffId,
      state: t(12),
      response_type: "code",
      code_challenge_method: "S256",
      code_challenge: _,
      liff_sdk_version: s()
    };
    i && i.redirectUri && (v.redirect_uri = i.redirectUri), d() && !a() && ((null === (m = h()) || void 0 === m ? void 0 : m.isReady()) ? v.redirect_uri = window.location.href : v.disable_auto_login = "true"), p({
      codeVerifier: g
    });
    var w = c("authorize") + "?" + n.stringify(v);
    r.debug("[Redirect] ".concat(w)), window.location.href = w;
  },
  b = function (o) {
    function r() {
      var i = null !== o && o.apply(this, arguments) || this;
      return i.hooks = {
        before: new g()
      }, i;
    }
    return i(r, o), Object.defineProperty(r.prototype, "name", {
      get: function () {
        return "login";
      },
      enumerable: !1,
      configurable: !0
    }), r.prototype.install = function () {
      return this._login.bind(this);
    }, r.prototype._login = function (i) {
      this.hooks.before.call(i), _(i);
    }, r;
  }(m);
export { b as LoginModule, _ as login };