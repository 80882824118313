@if (profile) {
<div class="page-container">
    <div class="page-content">
        <div class="search-box">
            <div class="search-group">
                <img class="icon" src="assets/images/icons/icon_search.svg">
                <input class="input-search" (keyup.enter)="onEnterSearch($event)">
            </div>
        </div>
        <form class="product-box" [formGroup]="productForm">
            <div class="product-list" formArrayName="products">
                @for (product of products; track product; let index = $index) {
                <div class="product" [formGroupName]="index">
                    <form [formGroupName]="product.id">
                        <div class="image">
                            <div class="chip">{{ product.price | currency }}</div>
                        </div>
                        <p class="title">{{ product.name }}</p>
                        <input class="input-field-small" placeholder="Enter quantity..." formControlName="quantity"
                            type="number">
                        <div class="buttom-group">
                            <button class="button-icon" (click)="decreaseQuantity(product)">-</button>
                            <button class="button-icon" (click)="increaseQuantity(product)">+</button>
                        </div>
                    </form>
                </div>
                }
            </div>
        </form>
    </div>
    <div class="page-bottom-action">
        <div class="shopping-cart">
            <img class="icon" src="assets/images/icons/icon_shopping_cart.svg">
            <div class="badge">{{ hasSelectedProducts.length }}</div>
        </div>
        <p class="total-price">{{ summaryPrice | currency }}</p>
        <button class="button-secondary" (click)="checkout()"
            [disabled]="hasSelectedProducts.length === 0">Checkout</button>
    </div>
</div>
}