import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { LIFFExtendedProfile } from '../../interfaces/liff-extended-profile';
import { ReplaySubject, takeUntil } from 'rxjs';
import { LiffService } from '../../services/liff.service';
import { EnvService } from '../../services/env.service';
import { CommonModule } from '@angular/common';
import { OnboardService } from '../../services/onboard.service';
import { Router } from '@angular/router';
import { CustomerService } from '../../services/customer.service';
import { Customer } from '../../interfaces/customer';
import { SessionStorageService } from '../../services/session-storage.service';

@Component({
  selector: 'app-onboard',
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
  ],
  templateUrl: './onboard.component.html',
  styleUrl: './onboard.component.scss'
})
export class OnboardComponent implements OnInit {
  public profile!: LIFFExtendedProfile;
  registerForm!: FormGroup;
  contactPersonName: string = '';

  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    private liffService: LiffService,
    private env: EnvService,
    private onboardService: OnboardService,
    private customerService: CustomerService,
    private router: Router,
    private formBuilder: FormBuilder,
    private storageService: SessionStorageService
  ) { }

  ngOnInit(): void {
    this.liffService.init$(this.env.config.liffApps['onboard'])
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (profile: LIFFExtendedProfile) => {
          this.profile = profile;
          this.contactPersonName = profile.displayName;
          this.goToOrderListPageIfAlreadyBeCustomer();
          this.createForm();
        },
        error: (e) => {
          if (!this.liffService.isLoggedIn()) {
            this.liffService.login('/onboard');
          }
        },
        complete: () => {
        }
      })
  }

  createForm() {
    this.registerForm = this.formBuilder.group({
      restaurantName: ['', Validators.required],
      name: [this.contactPersonName, Validators.required],
      phoneNo: ['', Validators.required],
    });
  }

  goToOrderListPageIfAlreadyBeCustomer() {
    this.customerService.get(this.profile.accessToken!)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (customer: Customer) => {
          if (!!customer) {
            this.router.navigate(['order'], { state: { customer: customer } });
          }
        },
        error: (e) => {
          console.log(e);
        },
        complete: () => {
        }
      });
  }

  onSubmit() {
    if (this.profile.accessToken && this.registerForm?.valid) {
      this.onboardService.onboard(this.profile.accessToken!, this.registerForm.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            this.storageService.setItem('successPage', JSON.stringify({
              buttonName: 'Start Order',
              naxtPath: '/products'
            }));
            this.router.navigate(['/success'])
          },
          error: (e) => {
          },
          complete: () => {
          }
        });
    }
  }
}
