import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable, of, Subject } from 'rxjs';
import { LIFFExtendedProfile } from '../interfaces/liff-extended-profile';
import liff from '@line/liff';
import { LIFFFriendFlag } from '../interfaces/liff-friendflag';
import LIFFProfile from '../interfaces/liff-profile';
import { LiffError } from '@liff/util';
// import querystring from 'query-string';
import querystring from 'qs';
import { EnvService } from './env.service';
import { WindowService } from './window.service';

@Injectable({
  providedIn: 'root'
})
export class LiffService {
  private profileSubject: Subject<LIFFExtendedProfile>;

  constructor(
    private envService: EnvService,
    private windowService: WindowService
  ) {
    this.profileSubject = new Subject<LIFFExtendedProfile>();
  }

  init$(liffId: string): Observable<LIFFExtendedProfile> {
    // init$
    // Initialize LIFF Session
    // - Initialize LIFF API
    // - Check if user logged in
    // - Check friendship between user and Messaging API
    return of({ accessToken: 'eyJhbGciOiJIUzI1NiJ9.Te2WZuorn74QYgQ4xY0oq4-ah656ssBRd17rBV_YNUN-eJ9rVp86Un1is4rmn530O7kxzhyf3OlmKgk7qFOUcZQ35AFVS1r6mZcgSWhTpvHNw3SxvBt019SFI60_tK1pKVl9ZdFKHIAWa0A2XOJlVj7ZMo5esgiTSZCiBittSuQ.e4WNFH1tE45ibwDRCPk4wOXuxlYL9hi4aHAIzVPuTIo', userId: '', pictureUrl: 'https://profile.line-scdn.net/0h5slBwGkvalxBO3oIYzkUIzFraTZiSjNOZQ1yPHFrMz94WS9ZOF52OicyY2x6Ay0KP1kmanU8MDtNKB06X22WaEYLN219DyQIZVUsug', displayName: '' } as LIFFExtendedProfile);
    liff.init({
      liffId: liffId
    }).then(() => {
      if (liff.isLoggedIn()) {
        const accessToken = liff.getAccessToken();
        liff.getFriendship().then((data: LIFFFriendFlag) => {
          if (data.friendFlag) {
            liff.getProfile()
              .then((profile: LIFFProfile) => {
                this.profileSubject.next(_.extend({}, profile, {accessToken: accessToken}));
              })
              .catch((err) => {
                this.profileSubject.error(err);
              });
          } else {
            console.log("go add friend");
            // Add friend first
            this.windowService.redirect(this.envService.config.redirectTo);
          }
        }).catch((err: LiffError) => {
          this.profileSubject.error(err);
        });
      } else {
        this.profileSubject.error(new Error('user is not logged in'));
      }
    }).catch((err) => {
      this.profileSubject.error(err);
    });

    return this.profileSubject.asObservable();
  }

  isLoggedIn(): boolean {
    return liff.isLoggedIn();
  }

  login(page: string, params?: { [key: string]: string; }): void {
    let qs = '';
    if (!!params) {
      qs = querystring.stringify(params);
    }

    liff.login({redirectUri: `${this.envService.config.homeUrl}${page}?${qs}`});
  }

  logout(): void {
    liff.logout();
  }

  isInLine(): boolean {
    return liff.isInClient();
  }

  close(): void {
    liff.closeWindow();
  }

  get accessToken(): string | null {
    return 'eyJhbGciOiJIUzI1NiJ9.Te2WZuorn74QYgQ4xY0oq4-ah656ssBRd17rBV_YNUN-eJ9rVp86Un1is4rmn530O7kxzhyf3OlmKgk7qFOUcZQ35AFVS1r6mZcgSWhTpvHNw3SxvBt019SFI60_tK1pKVl9ZdFKHIAWa0A2XOJlVj7ZMo5esgiTSZCiBittSuQ.e4WNFH1tE45ibwDRCPk4wOXuxlYL9hi4aHAIzVPuTIo';
    return liff.getAccessToken()
  }
}
