import { __extends as n } from "tslib";
import { LiffModule as t } from "@liff/use";
import { INVALID_CONFIG as r, STORE_KEY as e, STORE_OBJECT as o } from "@liff/consts";
import { isInClient as i } from "@liff/is-in-client";
import { createLiffError as u, cookie as f } from "@liff/util";
function c() {
  var n;
  return null !== (n = window.__liffConfig) && void 0 !== n ? n : {};
}
function l(n) {
  window.__liffConfig = n;
}
function a(n, t) {
  if (!t) throw u(r, "liffId is necessary for liff.init()");
  var o = (i() ? sessionStorage : localStorage).getItem("".concat(e, ":").concat(t, ":").concat(n));
  try {
    return null === o ? null : JSON.parse(o);
  } catch (f) {
    return null;
  }
}
function p(n) {
  return a(n, c().liffId);
}
function s(n, t) {
  var o = c().liffId;
  if (!o) throw u(r, "liffId is necessary for liff.init()");
  (i() ? sessionStorage : localStorage).setItem("".concat(e, ":").concat(o, ":").concat(n), JSON.stringify(t));
}
function E() {
  return p(o.CONTEXT);
}
function I(n) {
  s(o.CONTEXT, n);
}
var T = function (t) {
  function r() {
    return null !== t && t.apply(this, arguments) || this;
  }
  return n(r, t), Object.defineProperty(r.prototype, "name", {
    get: function () {
      return "getContext";
    },
    enumerable: !1,
    configurable: !0
  }), r.prototype.install = function () {
    return function () {
      return E();
    };
  }, r;
}(t);
function y() {
  return ((E() || {}).d || {}).aId;
}
var g = function (t) {
  function r() {
    return null !== t && t.apply(this, arguments) || this;
  }
  return n(r, t), Object.defineProperty(r.prototype, "name", {
    get: function () {
      return "getAId";
    },
    enumerable: !1,
    configurable: !0
  }), r.prototype.install = function () {
    return function () {
      return y();
    };
  }, r;
}(t);
function _() {
  return ((E() || {}).d || {}).autoplay || !1;
}
var m = function (t) {
  function r() {
    return null !== t && t.apply(this, arguments) || this;
  }
  return n(r, t), Object.defineProperty(r.prototype, "name", {
    get: function () {
      return "getIsVideoAutoPlay";
    },
    enumerable: !1,
    configurable: !0
  }), r.prototype.install = function () {
    return function () {
      return _();
    };
  }, r;
}(t);
function S() {
  return (E() || {}).profilePlus;
}
var d = function (t) {
  function r() {
    return null !== t && t.apply(this, arguments) || this;
  }
  return n(r, t), Object.defineProperty(r.prototype, "name", {
    get: function () {
      return "getProfilePlus";
    },
    enumerable: !1,
    configurable: !0
  }), r.prototype.install = function () {
    return function () {
      return S();
    };
  }, r;
}(t);
function O() {
  return Boolean(p(o.IS_SUBSEQUENT_LIFF_APP));
}
function P(n) {
  s(o.IS_SUBSEQUENT_LIFF_APP, n);
}
function b() {
  return p(o.APP_DATA);
}
function N(n) {
  s(o.APP_DATA, n);
}
function h() {
  return p(o.MST_VERIFIER);
}
function A(n) {
  s(o.MST_VERIFIER, n);
}
function v() {
  return p(o.MSIT);
}
function C(n) {
  s(o.MSIT, n);
}
function D() {
  return p(o.MST);
}
function L(n) {
  s(o.MST, n);
}
function M() {
  return p(o.MST_CHALLENGE);
}
function R(n) {
  s(o.MST_CHALLENGE, n);
}
function j() {
  return p(o.CLIENT_ID);
}
function F(n) {
  s(o.CLIENT_ID, n);
}
function K() {
  return p(o.RAW_CONTEXT);
}
function w() {
  return p(o.FEATURE_TOKEN);
}
function U(n) {
  s(o.FEATURE_TOKEN, n);
}
function X() {
  return p(o.ID_TOKEN);
}
function G(n) {
  s(o.ID_TOKEN, n);
}
var k = function (t) {
  function r() {
    return null !== t && t.apply(this, arguments) || this;
  }
  return n(r, t), Object.defineProperty(r.prototype, "name", {
    get: function () {
      return "getIDToken";
    },
    enumerable: !1,
    configurable: !0
  }), r.prototype.install = function () {
    return function () {
      return X();
    };
  }, r;
}(t);
function x() {
  return p(o.ACCESS_TOKEN);
}
function B(n) {
  s(o.ACCESS_TOKEN, n);
}
var V = function (t) {
  function r() {
    return null !== t && t.apply(this, arguments) || this;
  }
  return n(r, t), Object.defineProperty(r.prototype, "name", {
    get: function () {
      return "getAccessToken";
    },
    enumerable: !1,
    configurable: !0
  }), r.prototype.install = function () {
    return function () {
      return x();
    };
  }, r;
}(t);
function H(n) {
  var t = c().liffId;
  if (!t) throw u(r, "liffId is necessary for liff.init()");
  (i() ? sessionStorage : localStorage).removeItem("".concat(e, ":").concat(t, ":").concat(n));
}
function J() {
  return p(o.LOGIN_TMP);
}
function Q(n) {
  s(o.LOGIN_TMP, n);
}
function W() {
  H(o.LOGIN_TMP);
}
function q(n) {
  var t = c();
  f.set("".concat(e, ":").concat(o.EXPIRES, ":").concat(t.liffId), n.getTime(), {
    expires: n.toUTCString(),
    path: "/",
    secure: null
  });
}
function z() {
  var n = c();
  return f.get("".concat(e, ":").concat(o.EXPIRES, ":").concat(n.liffId));
}
function Y() {
  var n = c();
  f.remove("".concat(e, ":").concat(o.EXPIRES, ":").concat(n.liffId), {
    path: "/"
  });
}
function Z() {
  return p(o.DECODED_ID_TOKEN);
}
function $(n) {
  s(o.DECODED_ID_TOKEN, n);
}
var nn = function (t) {
  function r() {
    return null !== t && t.apply(this, arguments) || this;
  }
  return n(r, t), Object.defineProperty(r.prototype, "name", {
    get: function () {
      return "getDecodedIDToken";
    },
    enumerable: !1,
    configurable: !0
  }), r.prototype.install = function () {
    return function () {
      return Z();
    };
  }, r;
}(t);
function tn() {
  Object.keys(o).forEach(function (n) {
    H(o[n]);
  }), Y();
}
export { g as GetAIdModule, V as GetAccessTokenModule, T as GetContextModule, nn as GetDecodedIDTokenModule, k as GetIDTokenModule, m as GetIsVideoAutoPlayModule, d as GetProfilePlusModule, tn as clean, p as get, y as getAId, x as getAccessToken, b as getAppData, a as getByLiffId, j as getClientId, c as getConfig, E as getContext, Z as getDecodedIDToken, z as getExpireTime, w as getFeatureToken, X as getIDToken, O as getIsSubsequentLiffApp, _ as getIsVideoAutoPlay, J as getLoginTmp, v as getMSIT, D as getMST, M as getMSTChallenge, h as getMSTVerifier, S as getProfilePlus, K as getRawContext, H as remove, Y as removeExpireTime, W as removeLoginTmp, s as set, B as setAccessToken, N as setAppData, F as setClientId, l as setConfig, I as setContext, $ as setDecodedIDToken, q as setExpireTime, U as setFeatureToken, G as setIDToken, P as setIsSubsequentLiffApp, Q as setLoginTmp, C as setMSIT, L as setMST, R as setMSTChallenge, A as setMSTVerifier };