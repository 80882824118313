import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Router } from '@angular/router';
import { OrderService } from '../../services/order.service';
import { LiffService } from '../../services/liff.service';
import { ReplaySubject, takeUntil } from 'rxjs';
import { Order } from '../../interfaces/order';
import { EnvService } from '../../services/env.service';
import { LIFFExtendedProfile } from '../../interfaces/liff-extended-profile';
import { Customer } from '../../interfaces/customer';
import { CustomerService } from '../../services/customer.service';

@Component({
  selector: 'app-order',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatDividerModule
  ],
  templateUrl: './order.component.html',
  styleUrl: './order.component.scss'
})
export class OrderComponent implements OnInit {
  orders: Order[] = [];
  tab: string = 'order';
  public profile!: LIFFExtendedProfile;
  public customer: Customer | undefined;

  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    private router: Router,
    private liffService: LiffService,
    private orderService: OrderService,
    private env: EnvService,
    private customerService: CustomerService,
  ) { }

  ngOnInit(): void {
    this.liffService.init$(this.env.config.liffApps['order'])
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (profile: LIFFExtendedProfile) => {
        this.profile = profile;
        if(!this.customer) {
          this.fetchProfile(profile.accessToken!)
        }
        this.orderService.getAll(profile.accessToken!)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: (orders: Order[]) => {
              this.orders = orders;
            },
            error: (e) => {
            },
            complete: () => {
            }
          });
        },
        error: (e) => {
          if (!this.liffService.isLoggedIn()) {
            this.liffService.login('/order');
          }
        },
        complete: () => {
        }
      })
  }

  viewOrderDetail(order: Order): void {
    this.router.navigate([`/order/status`], { queryParams: { id: order.id } });
  }

  navigateToProduct() {
    this.router.navigate([`/products`]);
  }

  fetchProfile(accessToken: string) {
    this.customerService.get(accessToken)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (customer: Customer) => {
          this.customer = customer;
        },
        error: (e) => {            
          console.log(e);
        },
        complete: () => {
        }
      });
  }
}
