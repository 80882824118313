import { __extends as n, __read as i } from "tslib";
import { INVALID_ARGUMENT as t } from "@liff/consts";
import { createLiffError as o, compareVersion as e } from "@liff/util";
import { isInClient as r } from "@liff/is-in-client";
import { getOS as f } from "@liff/get-os";
import { getLineVersion as l } from "@liff/get-line-version";
import { call as c } from "@liff/native-bridge";
import { LiffModule as u } from "@liff/use";
var a = function (n, i) {
  return n ? "&".concat(n.split("&").filter(function (n) {
    return -1 === n.indexOf("is_liff_external_open_window");
  }).join("&").concat("".concat(i ? "#".concat(i) : ""))) : "".concat(i ? "#".concat(i) : "");
};
function p(n) {
  if (!function (n) {
    if (!n || "object" != typeof n) return !1;
    var t = n,
      o = t.url,
      e = t.external,
      r = i([typeof o, typeof e], 2),
      f = r[0],
      l = r[1];
    return "string" === f && "" !== o && ("undefined" === l || "boolean" === l);
  }(n)) throw o(t, "Invalid parameters for liff.openWindow()");
  var u = l();
  if (r()) {
    if (null !== u && "ios" === f() && e(u, "9.19") >= 0 || !window._liff.postMessage) {
      var p = n.url,
        s = n.external,
        d = void 0 !== s && s;
      window.open(function (n, t) {
        var o, e, r, f, l, c, u, p, s;
        (function (n) {
          return -1 !== n.indexOf("#") && -1 !== n.indexOf("?") && n.indexOf("#") < n.indexOf("?");
        })(n) || function (n) {
          return -1 === n.indexOf("?") && -1 !== n.indexOf("#");
        }(n) ? (u = (o = i(n.split("#"), 2))[0], e = o[1], p = (r = i((void 0 === e ? "" : e).split("?"), 2))[0], s = r[1]) : (u = (f = i(n.split("?"), 2))[0], l = f[1], s = (c = i((void 0 === l ? "" : l).split("#"), 2))[0], p = c[1]);
        var d = a(s, p);
        return "".concat(u, "?").concat("is_liff_external_open_window", "=").concat(!!t).concat(d);
      }(p, d));
    } else c("openWindow", n);
  } else window.open(n.url, "_blank");
}
var s = function (i) {
  function t() {
    return null !== i && i.apply(this, arguments) || this;
  }
  return n(t, i), Object.defineProperty(t.prototype, "name", {
    get: function () {
      return "openWindow";
    },
    enumerable: !1,
    configurable: !0
  }), t.prototype.install = function () {
    return function (n) {
      return p(n);
    };
  }, t;
}(u);
export { s as OpenWindowModule, p as openWindow };