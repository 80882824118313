 
<div class="order-list-container">
    @if (orders.length > 0) {
    <mat-list>
        @for (order of orders; track order) {
        <mat-list-item>
            <span matListItemTitle>Order #{{ order.id }}</span>
            @for (orderDetail of order.orderDetails; track orderDetail) {
                <div> - product name: {{ orderDetail.product?.name }}, price: {{ orderDetail.product?.price }}</div>
            }
            <span matListItemLine>Total amount {{ order.totalAmount }}</span>
            <span matListItemLine>Status {{ order.status }}, ref: {{ order.quickbookInvoiceRef }}</span>
            <button (click)="createInvoice(order.id)">Create Invoice</button>
        </mat-list-item>
        <mat-divider></mat-divider>
        }
    </mat-list>
    }
    @if (orders.length === 0) {
    <div *ngIf="orders.length === 0">
        <p>No orders found.</p>
    </div>
    }
</div>
  