import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SessionStorageService } from '../../services/session-storage.service';

interface SuccessPage {
  buttonName: string
  naxtPath: string
}

@Component({
  selector: 'app-success',
  standalone: true,
  imports: [],
  templateUrl: './success.component.html',
  styleUrl: './success.component.scss'
})
export class SuccessComponent {
  successPageData?: SuccessPage;

  constructor(
    private router: Router,
    private storageService: SessionStorageService
  ) {
    const successString = this.storageService.getItem('successPage');
    if (successString) {
      this.successPageData = JSON.parse(successString) as SuccessPage;
    }
  }

  navigateToNextPage(path: string) {
    this.storageService.removeItem('successPage');
    this.router.navigate([path]);
  }
}
