import { __extends as n } from "tslib";
import { LiffModule as t } from "@liff/use";
function r() {
  var n = navigator.userAgent.match(/Line\/\d+(\.\d+)*/i);
  return n ? n[0].slice(5) : null;
}
var e = function (t) {
  function e() {
    return null !== t && t.apply(this, arguments) || this;
  }
  return n(e, t), Object.defineProperty(e.prototype, "name", {
    get: function () {
      return "getLineVersion";
    },
    enumerable: !1,
    configurable: !0
  }), e.prototype.install = function () {
    return function () {
      return r();
    };
  }, e;
}(t);
export { e as GetLineVersionModule, r as getLineVersion };