import { __extends as t } from "tslib";
import { LiffModule as n } from "@liff/use";
var r;
function e() {
  if (!r) {
    var t = window.navigator.userAgent.toLowerCase();
    r = /iphone|ipad|ipod/.test(t) ? "ios" : /android/.test(t) ? "android" : "web";
  }
  return r;
}
function o() {
  r = void 0;
}
var i = function (n) {
  function r() {
    return n.call(this) || this;
  }
  return t(r, n), Object.defineProperty(r.prototype, "name", {
    get: function () {
      return "getOS";
    },
    enumerable: !1,
    configurable: !0
  }), r.prototype.install = function () {
    return function () {
      return e();
    };
  }, r;
}(n);
export { i as GetOSModule, o as _cleanupCachedOS, e as getOS };