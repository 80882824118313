import { Component, OnInit } from '@angular/core';
import { ReplaySubject, takeUntil } from 'rxjs';

import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Order } from '../../../interfaces/order';
import { AdminOrderService } from '../../services/admin-order.service';

@Component({
  selector: 'app-admin-order-list',
  standalone: true,
  imports: [ 
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatDividerModule
  ],
  templateUrl: './admin-order-list.component.html',
  styleUrl: './admin-order-list.component.scss'
})
export class AdminOrderListComponent implements OnInit {
  orders: Order[] = [];
  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    private orderService: AdminOrderService,
  ) {}

  ngOnInit(): void {
    this.orderService.getAll()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (orders: Order[]) => {
          this.orders = orders;
        },
        error: () => {
          
        }
      });
  }

  createInvoice(orderId: number) {
    this.orderService.createInvoice(orderId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (InvoicedOrder: Order) => {
          let pickOrder = this.orders.find(o => o.id === orderId);
          if(pickOrder) {
            pickOrder.quickbookInvoiceRef = InvoicedOrder.quickbookInvoiceRef;
          }
        },
        error: () => {

        }
      });
  }
  
}
