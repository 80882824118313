import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Product } from '../../interfaces/product';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ReplaySubject, takeUntil } from 'rxjs';
import { LIFFExtendedProfile } from '../../interfaces/liff-extended-profile';
import { EnvService } from '../../services/env.service';
import { LiffService } from '../../services/liff.service';
import { SelectedProduct } from '../../interfaces/selected-product';
import { ProductService } from '../../services/product.service';
import { SessionStorageService } from '../../services/session-storage.service';

@Component({
  selector: 'app-product-list',
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatToolbarModule,
    MatIconModule
  ],
  templateUrl: './product-list.component.html',
  styleUrl: './product-list.component.scss'
})
export class ProductListComponent {
  public profile!: LIFFExtendedProfile;
  products: SelectedProduct[] = [];
  selectedProducts: SelectedProduct[] = [];

  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    private router: Router,
    private liffService: LiffService,
    private env: EnvService,
    private productService: ProductService,
    private storageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.liffService.init$(this.env.config.liffApps['product-list'])
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (profile: LIFFExtendedProfile) => {
          this.profile = profile;
          this.getProductList();
        },
        error: (e) => {
          if (!this.liffService.isLoggedIn()) {
            this.liffService.login('/products');
          }
        },
        complete: () => {
        }
      })
  }

  getProductList() {
    this.productService.getAll(this.profile.accessToken!)
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (products: Product[]) => {
        if(products) {
          this.products = products.map((p: Product) => {
            return { 
              id: p.id,
              name: p.name,
              price: p.price,
              quantity: 0
            } as SelectedProduct;
          })
        }
      },
      error: (e) => {            
        console.log(e);
      },
      complete: () => {
      }
    });
  }

  increaseQuantity(product: SelectedProduct) {
    product.quantity++;
    const selectedProduct = this.selectedProducts.find(p => p.id === product.id);
    if (selectedProduct) {
      selectedProduct.quantity = product.quantity;
    } else {
      this.selectedProducts.push(product);
    }
  }

  decreaseQuantity(product: SelectedProduct) {
    if (product.quantity > 0) {
      product.quantity--;
      if(product.quantity === 0) {
        const productIndex = this.selectedProducts.findIndex(p => p.id === product.id);
        if (productIndex > -1) {
          this.selectedProducts.splice(productIndex, 1);
        }
      } else {
        const selectedProduct = this.selectedProducts.find(p => p.id === product.id);
        if (selectedProduct) {
          selectedProduct.quantity = product.quantity;
        }
      }
    }
  }

  checkout() {
    this.storageService.setItem('selectedProducts', JSON.stringify(this.selectedProducts));
    this.router.navigate(['/order/checkout']);
  }
}