import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WindowService {

  constructor() { }

  redirect(url: string) {
    window.location.href = url;
  }

  open(url: string) {
    window.open(url, '_blank');
  }
}
