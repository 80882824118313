import { __extends as e } from "tslib";
import { FORBIDDEN as r, UNAUTHORIZED as n, INVALID_ARGUMENT as i } from "@liff/consts";
import { LiffModule as a } from "@liff/use";
import { compareVersion as o, isLIFFBrowser as t, isLINEBrowser as l, createLiffError as c } from "@liff/util";
import { isSubWindow as s } from "@liff/is-sub-window";
import { getContext as d } from "@liff/store";
import { getLineVersion as u } from "@liff/get-line-version";
import { isInClient as f } from "@liff/is-in-client";
import { isLoggedIn as m } from "@liff/is-logged-in";
import { getOS as p } from "@liff/get-os";
function b(e) {
  var r,
    n = d();
  return null === (r = null == n ? void 0 : n.availability) || void 0 === r ? void 0 : r[e];
}
function v(e, n, i) {
  var a = b(e),
    t = i || e;
  if (!a) return {
    available: !1,
    error: {
      code: r,
      message: "".concat(t, " is not allowed in this LIFF app")
    }
  };
  var l = a.minVer,
    c = a.unsupportedFromVer,
    s = !l || function (e, r) {
      var n = u();
      return !!n && !(r && o(n, r) > 0) && o(n, e) >= 0;
    }(l, c),
    d = f();
  return d && !s ? {
    available: !1,
    error: {
      code: r,
      message: "".concat(t, " is unavailable in this client version.")
    }
  } : a.permission ? d && s || n ? {
    available: !0
  } : {
    available: !1,
    error: {
      code: r,
      message: "".concat(t, " is not allowed in external browser")
    }
  } : {
    available: !1,
    error: {
      code: r,
      message: "".concat(t, " is not allowed in this LIFF app")
    }
  };
}
var g = function () {
    return m() ? !t() && l() ? {
      available: !1,
      error: {
        code: r,
        message: "Subwindow is not supported in this browser"
      }
    } : s() ? {
      available: !1,
      error: {
        code: r,
        message: "this api can be only called in parent window"
      }
    } : v("subwindowOpen", !0) : {
      available: !1,
      error: {
        code: n,
        message: "Need access_token for api call, Please login first"
      }
    };
  },
  h = ["subwindowOpen", "shareTargetPicker", "multipleLiffTransition", "scanCode", "scanCodeV2", "getAdvertisingId", "addToHomeScreen", "bluetoothLeFunction", "skipChannelVerificationScreen", "createShortcutOnHomeScreen", "internalCreateShortcutOnHomeScreen"],
  S = {
    scanCode: function () {
      return v("scanCode");
    },
    getAdvertisingId: function () {
      return v("getAdvertisingId");
    },
    bluetoothLeFunction: function () {
      return v("bluetoothLeFunction");
    },
    shareTargetPicker: function () {
      return s() ? {
        available: !1,
        error: {
          code: r,
          message: "this api can be only called in parent window"
        }
      } : m() ? v("shareTargetPicker", !0) : {
        available: !1,
        error: {
          code: n,
          message: "Need access_token for api call, Please login first"
        }
      };
    },
    multipleLiffTransition: function () {
      var e = b("multipleLiffTransition");
      return e && e.permission ? f() ? {
        available: !0
      } : {
        available: !1,
        error: {
          code: r,
          message: "multipleLiffTransition is available only in the LINE App browser"
        }
      } : {
        available: !1,
        error: {
          code: r,
          message: "multipleLiffTransition is not allowed in this LIFF app"
        }
      };
    },
    subwindowOpen: g,
    scanCodeV2: function () {
      if (!m()) return {
        available: !1,
        error: {
          code: n,
          message: "Need access_token for api call, Please login first"
        }
      };
      var e = g();
      return e.available ? v("scanCodeV2", !0) : e;
    },
    addToHomeScreen: function () {
      return s() ? {
        available: !1,
        error: {
          code: r,
          message: "this api can be only called in parent window"
        }
      } : v("addToHomeScreen");
    },
    skipChannelVerificationScreen: function () {
      var e = d();
      return e ? "square_chat" === e.type ? {
        available: !1,
        error: {
          code: r,
          message: "skipChannelVerificationScreen is not allowed in OpenChat"
        }
      } : v("skipChannelVerificationScreen") : {
        available: !1,
        error: {
          code: r,
          message: "Context is not found"
        }
      };
    },
    createShortcutOnHomeScreen: function () {
      if (s()) return {
        available: !1,
        error: {
          code: r,
          message: "this api can be only called in parent window"
        }
      };
      if (!m()) return {
        available: !1,
        error: {
          code: n,
          message: "Need access_token for api call, Please login first"
        }
      };
      var e = p();
      return "android" !== e && "ios" !== e ? {
        available: !1,
        error: {
          code: r,
          message: "this api can be only called in mobile device"
        }
      } : v("addToHomeV2", !0, "createShortcutOnHomeScreen");
    },
    internalCreateShortcutOnHomeScreen: function () {
      if (s()) return {
        available: !1,
        error: {
          code: r,
          message: "this api can be only called in parent window"
        }
      };
      if (!m()) return {
        available: !1,
        error: {
          code: n,
          message: "Need access_token for api call, Please login first"
        }
      };
      var e = p();
      if ("android" !== e && "ios" !== e) return {
        available: !1,
        error: {
          code: r,
          message: "this api can be only called in mobile device"
        }
      };
      var i = v("addToHomeV2", !0, "internalCreateShortcutOnHomeScreen");
      return i.available ? v("addToHomeLineScheme", !0, "internalCreateShortcutOnHomeScreen") : i;
    }
  },
  w = function (e) {
    return function () {
      var r = e();
      if (!r.available) throw c(r.error.code, r.error.message);
    };
  },
  C = {
    scanCode: w(S.scanCode),
    getAdvertisingId: w(S.getAdvertisingId),
    bluetoothLeFunction: w(S.bluetoothLeFunction),
    shareTargetPicker: w(S.shareTargetPicker),
    multipleLiffTransition: w(S.multipleLiffTransition),
    subwindowOpen: w(S.subwindowOpen),
    scanCodeV2: w(S.scanCodeV2),
    addToHomeScreen: w(S.addToHomeScreen),
    skipChannelVerificationScreen: w(S.skipChannelVerificationScreen),
    createShortcutOnHomeScreen: w(S.createShortcutOnHomeScreen),
    internalCreateShortcutOnHomeScreen: w(S.internalCreateShortcutOnHomeScreen)
  };
function T(e) {
  if (!function (e) {
    return h.some(function (r) {
      return r === e;
    });
  }(e)) throw c(i, "Unexpected API name.");
  var r = S[e];
  return !r || r().available;
}
var H = function (r) {
  function n() {
    var e = null !== r && r.apply(this, arguments) || this;
    return e.hooks = {}, e;
  }
  return e(n, r), Object.defineProperty(n.prototype, "name", {
    get: function () {
      return "isApiAvailable";
    },
    enumerable: !1,
    configurable: !0
  }), n.prototype.install = function () {
    return function (e) {
      return T(e);
    };
  }, n;
}(a);
export { H as IsApiAvailableModule, T as isApiAvailable, C as validators };