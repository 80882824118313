import { __assign as e, __awaiter as t, __generator as n } from "tslib";
import { createLiffError as i, randomAlphaNumericString as r } from "@liff/util";
import { SUB_WINDOW_STATUS as s, UNKNOWN as a } from "@liff/consts";
var o = "liff.subwindow.identifier",
  c = "liff.subwindow.cryptokey",
  d = e(e({}, s), {
    GET_DATA: "getData",
    SET_DATA: "setData",
    NOT_FOUND: "notFound",
    TEARDOWN: "teardown"
  }),
  u = {
    BROADCAST: "broadcast",
    COMMAND: "command"
  },
  f = {
    MAIN: "main",
    SUB: "sub"
  },
  l = "__liff_message_bus__",
  h = "bFSQbce18HC7UXe-lS_mgg",
  v = function (e) {
    return t(void 0, void 0, void 0, function () {
      var t;
      return n(this, function (n) {
        switch (n.label) {
          case 0:
            return n.trys.push([0, 2,, 3]), [4, window.crypto.subtle.importKey("jwk", {
              kty: "oct",
              k: e,
              alg: "A128GCM",
              ext: !0
            }, {
              name: "AES-GCM"
            }, !1, ["encrypt", "decrypt"])];
          case 1:
            return [2, n.sent()];
          case 2:
            throw t = n.sent(), i(a, t);
          case 3:
            return [2];
        }
      });
    });
  },
  p = function (e, r, s) {
    return t(void 0, void 0, void 0, function () {
      var t, o, c, d;
      return n(this, function (n) {
        switch (n.label) {
          case 0:
            return n.trys.push([0, 3,, 4]), t = new TextEncoder().encode(e), [4, v(r)];
          case 1:
            return o = n.sent(), [4, window.crypto.subtle.encrypt({
              name: "AES-GCM",
              iv: t
            }, o, new TextEncoder().encode(s))];
          case 2:
            return c = n.sent(), [2, btoa(new Uint8Array(c).reduce(function (e, t) {
              return e + String.fromCharCode(t);
            }, ""))];
          case 3:
            throw d = n.sent(), i(a, d);
          case 4:
            return [2];
        }
      });
    });
  },
  m = function (e, r, s) {
    return t(void 0, void 0, void 0, function () {
      var t, o, c, d, u, f, l;
      return n(this, function (n) {
        switch (n.label) {
          case 0:
            return n.trys.push([0, 3,, 4]), t = new TextEncoder().encode(e), [4, v(r)];
          case 1:
            for (o = n.sent(), c = atob(s), d = new Uint8Array(c.length), u = 0; u < c.length; u++) d[u] = c.charCodeAt(u);
            return [4, window.crypto.subtle.decrypt({
              name: "AES-GCM",
              iv: t
            }, o, d.buffer)];
          case 2:
            return f = n.sent(), [2, new TextDecoder().decode(new Uint8Array(f))];
          case 3:
            throw l = n.sent(), i(a, l);
          case 4:
            return [2];
        }
      });
    });
  },
  w = function (e, t) {
    return y(e) === y(t);
  },
  y = function (e) {
    return "".concat(e.identifier, "-").concat(e.action, "-").concat(e.timestamp);
  },
  g = function (e) {
    return Object.keys(s).map(function (e) {
      return s[e];
    }).includes(e) ? u.BROADCAST : u.COMMAND;
  };
function b() {
  var e = document.createElement("form");
  e.method = "POST", e.action = "$MESSAGE_HANDLER_URL";
  var t = document.createElement("input");
  t.type = "hidden", t.name = "identifier", t.value = "$IDENTIFIER", e.appendChild(t), document.body.appendChild(e), e.submit();
}
var E = function (s) {
  void 0 === s && (s = f.MAIN);
  var o = this;
  this.identification = {
    identifier: "",
    cryptoKey: ""
  }, this.messageHandlerInstance = null, this.listeners = new Map(), this.sentMessages = [], this.generateIdentification = function () {
    return t(o, void 0, void 0, function () {
      var e, s, o, d, u;
      return n(this, function (l) {
        switch (l.label) {
          case 0:
            return e = new URLSearchParams(window.location.search), s = function (t) {
              var n = e.get("liff.state");
              return n ? new URLSearchParams(n).get(t) : null;
            }, o = this, u = {
              identifier: this.windowType === f.MAIN ? r(12) : e.get("liff.subwindow.identifier") || s("liff.subwindow.identifier") || ""
            }, this.windowType !== f.MAIN ? [3, 2] : [4, t(void 0, void 0, void 0, function () {
              var e, t, r;
              return n(this, function (n) {
                switch (n.label) {
                  case 0:
                    return n.trys.push([0, 3,, 4]), [4, window.crypto.subtle.generateKey({
                      name: "AES-GCM",
                      length: 128
                    }, !0, ["encrypt", "decrypt"])];
                  case 1:
                    return e = n.sent(), [4, window.crypto.subtle.exportKey("jwk", e)];
                  case 2:
                    if (!(t = n.sent()) || !t.k) throw i(a, "failed to generate key");
                    return [2, t.k];
                  case 3:
                    throw r = n.sent(), i(a, r);
                  case 4:
                    return [2];
                }
              });
            })];
          case 1:
            return d = l.sent(), [3, 3];
          case 2:
            d = e.get(c) || s(c) || "", l.label = 3;
          case 3:
            return o.identification = (u.cryptoKey = d, u), [2];
        }
      });
    });
  }, this.hasIdentification = function () {
    var e = o.identification,
      t = e.identifier,
      n = e.cryptoKey;
    return "string" == typeof t && "string" == typeof n && t.length > 0 && n.length > 0;
  }, this.isReady = function () {
    return o.hasIdentification() && !!o.messageHandlerInstance;
  }, this.setup = function () {
    return t(o, void 0, void 0, function () {
      var e,
        t,
        r,
        s,
        o,
        c = this;
      return n(this, function (n) {
        switch (n.label) {
          case 0:
            return this.messageHandlerInstance ? [2] : [4, this.generateIdentification()];
          case 1:
            if (n.sent(), !(e = this.identification.identifier)) return [2];
            if (t = /^[a-zA-Z0-9]+$/gm, !e.match(t)) throw i(a, "Invalid identifier");
            return (r = document.createElement("iframe")).style.display = "none", r.src = "about:blank", document.body.appendChild(r), null === (o = null == r ? void 0 : r.contentWindow) || void 0 === o || o.window.eval("(".concat(b.toString().replace("$MESSAGE_HANDLER_URL", "".concat("https://liff-subwindow.line.me/liff/v2/sub/messageHandler")).replace("$IDENTIFIER", e.split("'")[0]), ")()")), s = "iframe-".concat(e, "-ready"), [4, new Promise(function (e) {
              var t = function (n) {
                n.data[s] && (c.messageHandlerInstance = r, window.addEventListener("message", c.proxyToListeners), e(), document.removeEventListener("message", t));
              };
              window.addEventListener("message", t);
            })];
          case 2:
            return [2, n.sent()];
        }
      });
    });
  }, this.teardown = function () {
    return t(o, void 0, void 0, function () {
      var e, t;
      return n(this, function (n) {
        switch (n.label) {
          case 0:
            return this.isReady() ? [4, this.send({
              eventName: d.TEARDOWN
            })] : [3, 2];
          case 1:
            n.sent(), window.removeEventListener("message", this.proxyToListeners), this.listeners.clear(), null === (t = null === (e = this.messageHandlerInstance) || void 0 === e ? void 0 : e.parentNode) || void 0 === t || t.removeChild(this.messageHandlerInstance), this.messageHandlerInstance = null, n.label = 2;
          case 2:
            return [2];
        }
      });
    });
  }, this.listen = function (e) {
    o.listeners.set(e, e);
  }, this.listenRepliedEvent = function (e, t) {
    var n = function (i) {
      i.replyTarget && w(i.replyTarget, e) && (t(i), o.listeners.delete(n));
    };
    o.listeners.set(n, n);
  }, this.send = function (e) {
    return t(o, void 0, void 0, function () {
      var t,
        r,
        s,
        a,
        o = this;
      return n(this, function (n) {
        switch (n.label) {
          case 0:
            if (!this.isReady()) throw i("message bus is not ready to send message");
            return r = {
              action: g(e.eventName),
              identifier: this.identification.identifier || "",
              timestamp: new Date().getTime()
            }, [4, this.getEncryptedContext(e)];
          case 1:
            return r.context = n.sent(), t = r, null === (a = null === (s = this.messageHandlerInstance) || void 0 === s ? void 0 : s.contentWindow) || void 0 === a || a.postMessage({
              messageBusEvent: t
            }, "*"), this.sentMessages.push(y(t)), [4, new Promise(function (e) {
              o.listenRepliedEvent(t, function (t) {
                e(t.context);
              });
            })];
          case 2:
            return [2, n.sent()];
        }
      });
    });
  }, this.reply = function (e, r) {
    return t(o, void 0, void 0, function () {
      var t, s, o, c;
      return n(this, function (n) {
        switch (n.label) {
          case 0:
            if (!this.isReady()) throw i("message bus is not ready to send message");
            if (!e.identifier || !e.timestamp) throw i(a, "target message is not valid");
            return s = {
              action: u.BROADCAST
            }, [4, this.getEncryptedContext(r)];
          case 1:
            return s.context = n.sent(), s.identifier = this.identification.identifier || "", s.timestamp = new Date().getTime(), s.replyTarget = {
              action: e.action,
              identifier: e.identifier,
              timestamp: e.timestamp
            }, t = s, null === (c = null === (o = this.messageHandlerInstance) || void 0 === o ? void 0 : o.contentWindow) || void 0 === c || c.postMessage({
              messageBusEvent: t
            }, "*"), this.sentMessages.push(y(t)), [2];
        }
      });
    });
  }, this.setData = function (e, t) {
    void 0 === e && (e = "appData"), o.send({
      eventName: d.SET_DATA,
      key: e,
      data: t
    });
  }, this.getData = function (e) {
    return void 0 === e && (e = "appData"), t(o, void 0, void 0, function () {
      return n(this, function (t) {
        switch (t.label) {
          case 0:
            return [4, this.send({
              eventName: d.GET_DATA,
              key: e
            })];
          case 1:
            return [2, t.sent()];
        }
      });
    });
  }, this.proxyToListeners = function (i) {
    return t(o, void 0, void 0, function () {
      var r,
        s = this;
      return n(this, function (a) {
        return (r = i.data.messageBusEvent) ? (this.sentMessages.includes(y(r)) || r.identifier !== this.identification.identifier || r.action !== u.BROADCAST && !r.replyTarget || this.listeners.forEach(function (i) {
          return t(s, void 0, void 0, function () {
            var t, s, a;
            return n(this, function (n) {
              switch (n.label) {
                case 0:
                  return t = i, s = [e({}, r)], a = {}, [4, this.getDecryptedContext(r.context)];
                case 1:
                  return t.apply(void 0, [e.apply(void 0, s.concat([(a.context = n.sent(), a)]))]), [2];
              }
            });
          });
        }), [2]) : [2];
      });
    });
  }, this.getEncryptedContext = function (e) {
    return t(o, void 0, void 0, function () {
      var t, i, r, s, a, o, c;
      return n(this, function (n) {
        switch (n.label) {
          case 0:
            return t = this.identification, i = t.identifier, r = t.cryptoKey, a = (s = JSON).stringify, c = {
              eventName: e.eventName,
              key: e.key ? e.key : void 0
            }, e.data ? [4, p(i, r, JSON.stringify(e.data))] : [3, 2];
          case 1:
            return o = n.sent(), [3, 3];
          case 2:
            o = void 0, n.label = 3;
          case 3:
            return [2, a.apply(s, [(c.data = o, c)])];
        }
      });
    });
  }, this.getDecryptedContext = function (i) {
    return t(o, void 0, void 0, function () {
      var t, r, s, a, o, c, d, u;
      return n(this, function (n) {
        switch (n.label) {
          case 0:
            return t = this.identification, r = t.identifier, s = t.cryptoKey, (a = JSON.parse(i)).data && "string" == typeof a.data ? (u = (d = JSON).parse, [4, m(r, s, a.data)]) : [3, 2];
          case 1:
            return c = u.apply(d, [n.sent()]), [3, 3];
          case 2:
            c = void 0, n.label = 3;
          case 3:
            return o = c, [2, e(e({}, a), {
              data: o
            })];
        }
      });
    });
  }, this.windowType = s;
};
export { u as ACTION, c as CRYPTO_KEY, d as EVENT_NAME, l as GLOBAL_MESSAGE_BUS_IDENTIFIER, h as GLOBAL_MESSAGE_BUS_KEY, o as IDENTIFIER_KEY, E as MessageBus, f as WINDOW, g as getEventAction, y as getUniqId, w as isSameEvent, b as loadMessageHandlerPage };