var e = "UNKNOWN",
  t = "UNAUTHORIZED",
  i = "INVALID_ARGUMENT",
  I = "INIT_FAILED",
  E = "FORBIDDEN",
  n = "INVALID_CONFIG",
  T = "INVALID_ID_TOKEN",
  _ = "TIMEOUT",
  f = "CREATE_SUBWINDOW_FAILED",
  N = "EXCEPTION_IN_SUBWINDOW",
  o = "liffEvent",
  l = "LIFF_STORE",
  s = "https://liff.".concat("line.me", "/"),
  a = "https://miniapp.".concat("line.me", "/"),
  c = {
    ACCESS_TOKEN: "accessToken",
    ID_TOKEN: "IDToken",
    DECODED_ID_TOKEN: "decodedIDToken",
    FEATURE_TOKEN: "featureToken",
    LOGIN_TMP: "loginTmp",
    CONFIG: "config",
    CONTEXT: "context",
    EXPIRES: "expires",
    RAW_CONTEXT: "rawContext",
    CLIENT_ID: "clientId",
    IS_SUBSEQUENT_LIFF_APP: "isSubsequentLiffApp",
    MST_CHALLENGE: "mstChallenge",
    MSIT: "msit",
    MST: "mst",
    MST_VERIFIER: "mstVerifier",
    APP_DATA: "appData"
  },
  r = "isInClient",
  D = ["context_token", "feature_token", "access_token", "id_token", "client_id", "mst_verifier", "mst_challenge", "msit"],
  O = 5,
  A = ["liff.ref.source", "liff.ref.medium", "liff.ref.campaign", "liff.ref.term", "liff.ref.content"],
  m = "liff://subwindow",
  C = {
    INIT: "init",
    SUBMIT: "submit",
    CANCEL: "cancel",
    CLOSE: "close",
    ERROR: "error"
  },
  p = 100,
  S = 100,
  L = "liff.subwindow",
  R = "healthCheck",
  F = "getAppData",
  U = ["profile", "chat_message.write", "openid", "email"];
export { f as CREATE_SUBWINDOW_FAILED, D as CREDENTIAL_KEYS, N as EXCEPTION_IN_SUBWINDOW, E as FORBIDDEN, I as INIT_FAILED, i as INVALID_ARGUMENT, n as INVALID_CONFIG, T as INVALID_ID_TOKEN, o as LIFF_EVENT, O as MAX_NUM_OF_SEND_MESSAGES, s as PERMANENT_LINK_ORIGIN, a as PERMANENT_LINK_ORIGIN_MINI, U as PERMISSION_NAMES, l as STORE_KEY, c as STORE_OBJECT, r as STORE_SUBKEY_IS_IN_CLIENT, F as SUB_WINDOW_GET_APP_DATA_MESSAGE, S as SUB_WINDOW_HEALTH_CHECK_INTERVAL, R as SUB_WINDOW_HEALTH_CHECK_MESSAGE, L as SUB_WINDOW_IDNTIFICATION_KEY, m as SUB_WINDOW_MODAL_SCHEME_URL, p as SUB_WINDOW_MONITOR_CLOSE_INTERVAL, C as SUB_WINDOW_STATUS, _ as TIMEOUT, t as UNAUTHORIZED, e as UNKNOWN, A as UTS_REFERRER_QUERY };