import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Product } from '../interfaces/product';
import { EnvService } from './env.service';
import { Customer } from '../interfaces/customer';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private http: HttpClient, private env: EnvService) { }

  public get(customerAccessToken: string): Observable<Customer> {
    const headers: { [header: string]: string } = this.getHttpHeaders(customerAccessToken);
    return this.http.get<Customer>(`${this.env.config.apiUrl}/customer/profile`, {
      headers
    });
  }

  private getHttpHeaders(customerAccessToken: string): { [header: string]: string } {
    return {
      'X-Line-Channel-ID': this.env.config.liffChannelId,
      'X-Line-Access-Token': customerAccessToken
    };
  }
}
