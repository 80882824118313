@if (profile) {
    <mat-toolbar color="primary">
        <span>Order Status</span>
        <span class="spacer"></span>
        <div class="profile-picture-container">
            <div class="profile-picture" [ngStyle]="{'background-image': 'url(' + profile.pictureUrl + ')'}"></div>
        </div>
    </mat-toolbar>
    
    @if (order) {
    <div class="order-list">
        <mat-list>
            <mat-list-item class="order-details">
                <span matListItemTitle>Order id : {{ order.id }}</span>
                <span matListItemTitle>Order status : {{ order.status }}</span>
            </mat-list-item>
        </mat-list>
        <mat-list>
            @for (order of order.orderDetails; track $index) {
            <mat-list-item class="order-details">
                <span matListItemTitle>Product: {{ order.productId }}</span>
                <span matListItemLine>Unit Price: {{ order.unitPrice| currency }}</span>
                <span matListItemLine>Quantity: {{ order.quantity }}</span>
                <span matListItemLine>Total: {{ order.totalPrice| currency }}</span>
            </mat-list-item>
            <mat-divider></mat-divider>
            }
        </mat-list>

        <mat-list>
            <mat-list-item>
                <p><strong>Grand Total: {{ order.totalAmount | currency }}</strong></p>
            </mat-list-item>
        </mat-list>
    </div>

    } @else {
        <div>
            <p>No products selected.</p>
        </div>
    }
}