import { __awaiter as t, __generator as e, __read as n, __spreadArray as r, __extends as i, __assign as o } from "tslib";
import { LiffModule as s } from "@liff/use";
import { isInClient as u } from "@liff/is-in-client";
import { validators as a } from "@liff/is-api-available";
import { STORE_KEY as c, INVALID_CONFIG as f, EXCEPTION_IN_SUBWINDOW as l, SUB_WINDOW_STATUS as d, CREATE_SUBWINDOW_FAILED as m, SUB_WINDOW_IDNTIFICATION_KEY as v, INVALID_ARGUMENT as p, SUB_WINDOW_HEALTH_CHECK_MESSAGE as h, SUB_WINDOW_HEALTH_CHECK_INTERVAL as w, SUB_WINDOW_MONITOR_CLOSE_INTERVAL as g, UNKNOWN as b, UNAUTHORIZED as y, SUB_WINDOW_MODAL_SCHEME_URL as S } from "@liff/consts";
import { logger as I } from "@liff/logger";
import { createLiffError as O, InMemoryStorage as P, isNonBrowserEnvironment as C, isIpad as L, extractLiffId as E } from "@liff/util";
import { getOS as N } from "@liff/get-os";
import { getEndPoint as T, fetch as j, requestWithoutErrorHandling as M } from "@liff/server-api";
import { getConfig as B, getMSTChallenge as D, getMST as A, getAppData as J } from "@liff/store";
import { WINDOW as R, MessageBus as U, IDENTIFIER_KEY as W, CRYPTO_KEY as x } from "@liff/message-bus";
import { isSubWindow as k } from "@liff/is-sub-window";
import { closeWindow as K } from "@liff/close-window";
function G(t) {
  var e = T("subWindowGetOrigin");
  return j(e(t));
}
var V = {};
function F(t, e) {
  t && V[t] && V[t].forEach(function (t) {
    t(e);
  });
}
function _(t, e) {
  V[t] || (V[t] = []), V[t].push(e);
}
function q(t, e) {
  if (V[t]) {
    var n = V[t].indexOf(e);
    n >= 0 && V[t].splice(n, 1);
  }
}
var z,
  H,
  Q,
  X,
  Y,
  Z = function () {
    function t(t) {
      this.storage = t;
    }
    return t.prototype.getItem = function (t) {
      return this.storage.getItem("".concat(this.getKeyPrefix(), ":").concat(t));
    }, t.prototype.setItem = function (t, e) {
      this.storage.setItem("".concat(this.getKeyPrefix(), ":").concat(t), e);
    }, t.prototype.removeItem = function (t) {
      this.storage.removeItem("".concat(this.getKeyPrefix(), ":").concat(t));
    }, t.prototype.clear = function () {
      this.storage.clear();
    }, t.prototype.getKeyPrefix = function () {
      return "".concat(c, ":").concat(this.getLiffId());
    }, t.prototype.getLiffId = function () {
      var t = B().liffId;
      if (!t) throw O(f, "liffId is necessary for liff.init()");
      return t;
    }, t;
  }(),
  $ = new Z(new P());
function tt() {
  var t = $.getItem("subWindowStatusUpdated");
  return null !== t && JSON.parse(t);
}
function et(t) {
  $.setItem("subWindowStatusUpdated", String(t));
}
function nt(t) {
  z = t;
}
function rt() {
  return z;
}
function it() {
  return Q;
}
function ot() {
  return X;
}
function st(n) {
  return void 0 === n && (n = R.MAIN), t(this, void 0, void 0, function () {
    return e(this, function (t) {
      switch (t.label) {
        case 0:
          return [4, (Y = new U(n)).setup()];
        case 1:
          return t.sent(), [2, Y];
      }
    });
  });
}
function ut() {
  return Y;
}
var at = new P(),
  ct = new Z(C() ? at : window.sessionStorage);
function ft(t) {
  ct.setItem("mainWindowOrigin", t);
}
function lt() {
  return ct.getItem("mainWindowOrigin");
}
function dt(n, r) {
  return void 0 === r && (r = {}), t(this, void 0, void 0, function () {
    var t, i, o, s, u, a, c, f;
    return e(this, function (e) {
      switch (e.label) {
        case 0:
          if (null == (t = ut()) ? void 0 : t.isReady()) return [3, 5];
          if (i = JSON.stringify(r), o = B().liffId, s = lt(), !window.opener || !s || !o) throw O(l);
          u = !1, e.label = 1;
        case 1:
          return e.trys.push([1, 3,, 4]), [4, G(o)];
        case 2:
          return a = e.sent(), u = a.subwindowCommonModule, [3, 4];
        case 3:
          throw c = e.sent(), I.debug(c), O(l);
        case 4:
          return f = u ? s : location.origin, [2, new Promise(function (t) {
            window.addEventListener("message", function e(r) {
              (function (t) {
                if (t.data && "string" == typeof t.data.type && [d.SUBMIT, d.CANCEL].includes(t.data.type)) return !0;
                return !1;
              })(r) && (window.removeEventListener("message", e), t({
                status: n,
                result: i
              }));
            }), window.opener.postMessage({
              status: n,
              result: i
            }, f);
          })];
        case 5:
          return t.send({
            eventName: n,
            data: r
          }), [4, new Promise(function (t) {
            setTimeout(t, 500);
          })];
        case 6:
          return e.sent(), [2, {
            status: n,
            result: JSON.stringify(r)
          }];
      }
    });
  });
}
function mt(t) {
  var e,
    n = ot();
  if (t.origin === n) {
    var r = t.data;
    if (r) {
      var i,
        o = r.status,
        s = r.result;
      try {
        i = JSON.parse(s || "{}");
      } catch (u) {
        i = {};
      }
      switch (o) {
        case h:
          window.clearInterval(it()), wt();
          break;
        case d.CANCEL:
        case d.SUBMIT:
          et(!0), window.clearInterval(it()), window.removeEventListener("message", mt), F(o, i), null === (e = rt()) || void 0 === e || e.postMessage({
            type: o
          }, ot());
          break;
        default:
          I.debug("unexpected message");
      }
    }
  }
}
var vt = function (n) {
  return t(void 0, void 0, void 0, function () {
    var t, r, i, o;
    return e(this, function (e) {
      if (tt()) return [2];
      switch (t = n.context, r = t.eventName, i = t.data, o = ut(), r) {
        case d.INIT:
          ht(!i.hasOpener);
          break;
        case d.CANCEL:
        case d.SUBMIT:
          et(!0), F(r, i), null == o || o.reply(n, {
            eventName: r
          });
          break;
        case d.CLOSE:
          !1 === tt() && (et(!0), F(d.CLOSE, {})), wt();
      }
      return [2];
    });
  });
};
function pt() {
  window.clearInterval(H), window.clearInterval(it()), window.removeEventListener("message", mt);
}
function ht(t) {
  if (void 0 === t && (t = !1), pt(), et(!1), t) {
    var e = rt();
    e && (e.close(), nt(null));
  }
}
function wt() {
  return t(this, void 0, void 0, function () {
    var t;
    return e(this, function (e) {
      switch (e.label) {
        case 0:
          return (t = ut()) ? [4, t.teardown()] : [3, 2];
        case 1:
          e.sent(), e.label = 2;
        case 2:
          return [2];
      }
    });
  });
}
function gt(i) {
  var o;
  return t(this, void 0, void 0, function () {
    var t, s, u, a, c, f, l, b, y, S, I;
    return e(this, function (e) {
      switch (e.label) {
        case 0:
          return (t = E(i.url)) ? (ht(!0), [4, wt()]) : [2, Promise.reject(O(p, "params.url must be liff url or mini url"))];
        case 1:
          return e.sent(), nt("ios" !== N() || L() ? window.open("", "liffsubwindow", "width=480, height=640, menubar=no, toolbar=no, scrollbars=yes") : window.open()), s = i.url, u = i.appData, (a = new URL(s)).searchParams.append(v, "true"), [4, st()];
        case 2:
          return c = e.sent(), a.searchParams.append(W, c.identification.identifier), a.searchParams.append(x, c.identification.cryptoKey), a.hostname = function (t) {
            var e = n(t.split(".")),
              i = e[0],
              o = e.slice(1);
            return r(["".concat(i, "-ext")], n(o), !1).join(".");
          }(a.hostname), f = a.toString(), [4, G(t)];
        case 3:
          if (l = e.sent(), b = l.origin, y = l.subwindowCommonModule, !(S = rt())) throw O(m);
          if (!y) return S.close(), [2];
          !function (t) {
            X = t;
          }(b), c.listen(vt), c.setData("appData", u), window.addEventListener("message", mt), e.label = 4;
        case 4:
          return e.trys.push([4, 6,, 7]), [4, null === (o = i.onBeforeTransition) || void 0 === o ? void 0 : o.call(i)];
        case 5:
          return e.sent(), [3, 7];
        case 6:
          throw I = e.sent(), S.close(), I;
        case 7:
          return S.location.href = f, P = function (t, e) {
            var n = rt(),
              r = {
                type: h
              };
            return e && (r.message = JSON.stringify(e)), window.setInterval(function () {
              null == n || n.postMessage(r, t);
            }, w);
          }(b, u), Q = P, function (t) {
            H = t;
          }(window.setInterval(function () {
            var t = rt();
            t && t.closed && (pt(), nt(null), !1 === tt() && (et(!0), F(d.CLOSE, {})));
          }, g)), [2];
      }
      var P;
    });
  });
}
var bt = null;
function yt(i) {
  return t(this, void 0, void 0, function () {
    var o,
      s,
      u,
      a,
      c,
      f,
      l,
      m,
      v,
      h,
      w,
      g,
      y,
      S,
      I = this;
    return e(this, function (P) {
      switch (P.label) {
        case 0:
          if (o = i.msit, s = i.mstChallenge, u = i.reconnectCount, a = void 0 === u ? 0 : u, c = function () {
            return t(I, void 0, void 0, function () {
              return e(this, function (t) {
                switch (t.label) {
                  case 0:
                    return [4, (e = 1e3, new Promise(function (t) {
                      return setTimeout(t, e);
                    }))];
                  case 1:
                    return t.sent(), [4, yt({
                      msit: o,
                      mstChallenge: s,
                      onSuccess: i.onSuccess,
                      onError: i.onError,
                      reconnectCount: a + 1
                    })];
                  case 2:
                    return t.sent(), [2];
                }
                var e;
              });
            });
          }, f = function () {
            for (var t = [], e = 0; e < arguments.length; e++) t[e] = arguments[e];
            bt = null, i.onSuccess.apply(i, r([], n(t), !1));
          }, l = function () {
            for (var t = [], e = 0; e < arguments.length; e++) t[e] = arguments[e];
            bt = null, i.onError.apply(i, r([], n(t), !1));
          }, m = Date.now(), null === bt && (bt = m), v = m - bt, a >= 10 || v > 6e5) return l(O(b, "Failed to connect")), [2];
          P.label = 1;
        case 1:
          return P.trys.push([1, 3,, 5]), [4, M(T("subWindowSubscribe"), {
            method: "POST",
            body: JSON.stringify({
              msit: o,
              mstChallenge: s
            })
          })];
        case 2:
          return h = P.sent(), [3, 5];
        case 3:
          return P.sent(), [4, c()];
        case 4:
          return P.sent(), [2];
        case 5:
          return h.status >= 500 ? [4, c()] : [3, 7];
        case 6:
          return P.sent(), [3, 17];
        case 7:
          return h.status >= 400 && 500 > h.status ? [4, St(h)] : [3, 9];
        case 8:
          return g = P.sent(), w = g ? O(p, g.errorDetail) : O(b, "Some error happened in the server"), l(w), [3, 17];
        case 9:
          return 200 !== h.status ? [3, 16] : [4, St(h)];
        case 10:
          if (!(g = P.sent())) return l(O(b, "Some error happened in the server")), [2];
          switch (y = g.status, S = g.result, y) {
            case d.ERROR:
              return [3, 11];
            case d.CLOSE:
            case d.CANCEL:
            case d.SUBMIT:
              return [3, 13];
          }
          return [3, 14];
        case 11:
          return [4, c()];
        case 12:
          return P.sent(), [3, 15];
        case 13:
          return f(y, S), [3, 15];
        case 14:
          l(O(b, "Some error happened in the server")), P.label = 15;
        case 15:
          return [3, 17];
        case 16:
          l(O(b, "Some error happened in the server")), P.label = 17;
        case 17:
          return [2];
      }
    });
  });
}
function St(n) {
  return t(this, void 0, void 0, function () {
    return e(this, function (t) {
      switch (t.label) {
        case 0:
          return t.trys.push([0, 2,, 3]), [4, n.json()];
        case 1:
          return [2, t.sent()];
        case 2:
          return t.sent(), [2, null];
        case 3:
          return [2];
      }
    });
  });
}
function It(t) {
  var e = {};
  return Object.keys(t).forEach(function (n) {
    "closeButtonColor" === n ? "white" === t[n] ? e[n] = "#ffffff" : e[n] = "#000000" : e[n] = t[n];
  }), e;
}
var Ot = {
  height: "full",
  closeButtonPosition: "right",
  closeButtonColor: "black",
  closeButtonLabel: ""
};
function Pt(n) {
  var r = n.appData,
    i = n.native,
    o = B().liffId,
    s = D(),
    u = E(n.url);
  if (!o) return Promise.reject(O(y, "liffId is invalid"));
  if (!s) return Promise.reject(O(y, "mst_challenge is invalid"));
  if (!u) return Promise.reject(O(p, "params.url must be liff url or mini url"));
  var a = Object.assign({}, Ot, i);
  return function (t) {
    var e = t.mainLiffId,
      n = t.subLiffId,
      r = t.mstChallenge,
      i = t.appData,
      o = t.view;
    return e && r ? j(T("subWindowGetMSIT"), {
      method: "POST",
      body: JSON.stringify({
        mainLiffId: e,
        subLiffId: n,
        mstChallenge: r,
        appData: i,
        view: o
      })
    }) : Promise.reject(O(p, "no proper argument"));
  }({
    mainLiffId: o,
    subLiffId: u,
    mstChallenge: s,
    appData: r,
    view: It(a)
  }).then(function (t) {
    var e = t.msit;
    return yt({
      msit: e,
      mstChallenge: s,
      onSuccess: function (t, e) {
        F(t, e);
      },
      onError: function (t) {
        F(d.ERROR, t);
      }
    }), e;
  }).then(function (r) {
    return function (n, r) {
      var i;
      return t(this, void 0, void 0, function () {
        var t, o;
        return e(this, function (e) {
          switch (e.label) {
            case 0:
              return t = n.url, (o = new URLSearchParams()).set("msit", r), [4, null === (i = n.onBeforeTransition) || void 0 === i ? void 0 : i.call(n)];
            case 1:
              return e.sent(), location.href = "".concat(S, "?url=").concat(encodeURIComponent(t), "&").concat(o.toString()), [2];
          }
        });
      });
    }(n, r);
  });
}
function Ct(t) {
  return a.subwindowOpen(), u() ? Pt(t) : gt(t);
}
function Lt(t) {
  if (!t.mst || !t.status) return Promise.reject(O(p, "no proper argument"));
  var e = JSON.stringify(t);
  return j(T("subWindowPost"), {
    method: "POST",
    body: e
  });
}
function Et() {
  if (!k()) throw O(y, "this api can be only called in child window");
}
function Nt(n) {
  return void 0 === n && (n = {}), Et(), u() ? function (n) {
    return void 0 === n && (n = {}), t(this, void 0, void 0, function () {
      var t, r;
      return e(this, function (e) {
        switch (e.label) {
          case 0:
            return (t = A()) ? [4, Lt({
              mst: t,
              status: d.CANCEL,
              result: n
            })] : [2, Promise.reject(O(y, "mst is invalid"))];
          case 1:
            return r = e.sent(), et(!0), [2, r];
        }
      });
    });
  }(n) : function (t) {
    return void 0 === t && (t = {}), dt(d.CANCEL, t);
  }(n);
}
function Tt(n) {
  return void 0 === n && (n = {}), Et(), u() ? function (n) {
    return void 0 === n && (n = {}), t(this, void 0, void 0, function () {
      var t, r;
      return e(this, function (e) {
        switch (e.label) {
          case 0:
            return (t = A()) ? [4, Lt({
              mst: t,
              status: d.SUBMIT,
              result: n
            })] : [2, Promise.reject(O(y, "mst is invalid"))];
          case 1:
            return r = e.sent(), et(!0), [2, r];
        }
      });
    });
  }(n) : function (t) {
    return void 0 === t && (t = {}), dt(d.SUBMIT, t);
  }(n);
}
function jt() {
  return Et(), u() ? function () {
    return t(this, void 0, void 0, function () {
      var t;
      return e(this, function (e) {
        switch (e.label) {
          case 0:
            return !1 !== tt() ? [3, 2] : (t = A()) ? [4, Lt({
              mst: t,
              status: d.CLOSE,
              result: {}
            })] : [2, Promise.reject(O(y, "mst is invalid"))];
          case 1:
            e.sent(), e.label = 2;
          case 2:
            return K(), [2];
        }
      });
    });
  }() : function () {
    return t(this, void 0, void 0, function () {
      var t;
      return e(this, function (e) {
        return (null == (t = ut()) ? void 0 : t.isReady()) ? (t.send({
          eventName: d.CLOSE
        }), [2, new Promise(function (t) {
          setTimeout(function () {
            K(), t();
          }, w);
        })]) : (K(), [2, Promise.resolve()]);
      });
    });
  }();
}
function Mt() {
  return Et(), function () {
    var t,
      e = J();
    try {
      t = e ? JSON.parse(e) : {};
    } catch (n) {
      t = {};
    }
    return Promise.resolve(t);
  }();
}
function Bt(t) {
  var e = t.msit,
    n = t.mstVerifier;
  return e && n ? j(T("subWindowGetMSTByMSIT"), {
    method: "POST",
    body: JSON.stringify({
      msit: e,
      mstVerifier: n
    })
  }) : Promise.reject(O(p, "no proper argument"));
}
function Dt(t) {
  var e = t.mst;
  return e ? j(T("subWindowGetAppData"), {
    method: "POST",
    body: JSON.stringify({
      mst: e
    })
  }) : Promise.reject(O(p, "no proper argument"));
}
var At = {
    on: _,
    off: q,
    open: Ct,
    cancel: Nt,
    submit: Tt,
    close: jt,
    getAppData: Mt
  },
  Jt = function (t) {
    function e() {
      return null !== t && t.apply(this, arguments) || this;
    }
    return i(e, t), Object.defineProperty(e.prototype, "name", {
      get: function () {
        return "subWindow";
      },
      enumerable: !1,
      configurable: !0
    }), e.prototype.install = function () {
      var t = this;
      return {
        on: _.bind(this),
        off: q.bind(this),
        open: function (e) {
          return Ct.call(t, o(o({}, e), {
            onBeforeTransition: void 0
          }));
        },
        cancel: Nt.bind(this),
        submit: Tt.bind(this),
        close: jt.bind(this),
        getAppData: Mt.bind(this)
      };
    }, e;
  }(s);
export { Jt as SubWindowModule, Dt as getAppData, Bt as getMSTByMSIT, lt as getMainWindowOrigin, ut as getMessageBus, st as initMessageBus, ft as setMainWindowOrigin, At as subWindow };