import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvService } from '../env.service';
import { AuthenRequest } from '../../interfaces/authen-request';
import { BearerToken } from '../../interfaces/bearer-token';
import { Observable, tap } from 'rxjs';
import { RefreshTokenRequest } from '../../interfaces/refresh-token-request';

@Injectable({
  providedIn: 'root'
})
export class QuickbookService {
  token: BearerToken | undefined;
  constructor(private http: HttpClient, private env: EnvService) { }

  goLogin() {
    const qb = this.env.config.quickbook;
    window.location.href = `${qb.authEndpoint}?client_id=${qb.clientId}&response_type=${qb.responseType}&scope=${qb.scope}&redirect_uri=${qb.redirectUri}&state=${qb.state}`;
  }

  authen(authenRequest: AuthenRequest): Observable<BearerToken> {
    const url = `${this.env.config.apiUrl}/admin/authen`;
    return this.http.post<BearerToken>(url, authenRequest)
      .pipe(
        tap((token: BearerToken) => {
          this.token = token;
        })
      );
  }

  refreshToken(refreshToken: RefreshTokenRequest): Observable<BearerToken> {
    const url = `${this.env.config.apiUrl}/admin/token/refresh`;
    return this.http.post<BearerToken>(url, refreshToken)
      .pipe(
        tap((token: BearerToken) => {
          this.token = token;
        })
      );
  }
}
