@if (profile) {
<mat-toolbar color="primary">
    <span>Restaurant Onboarding</span>
</mat-toolbar>

<div class="container">
    <mat-card>
        <div class="profile-picture-container">
            <div class="profile-picture" [ngStyle]="{'background-image': 'url(' + profile.pictureUrl + ')'}"></div>
        </div>

        <mat-form-field appearance="fill" class="full-width">
            <mat-label>Restaurant Name</mat-label>
            <input matInput [(ngModel)]="restaurantName" name="restaurantName" required>
        </mat-form-field>

        <mat-form-field appearance="fill" class="full-width">
            <mat-label>Contact Person Name</mat-label>
            <input matInput [(ngModel)]="contactPersonName" name="contactPersonName" required>
        </mat-form-field>

        <mat-form-field appearance="fill" class="full-width">
            <mat-label>Contact Person Phone</mat-label>
            <input matInput [(ngModel)]="contactPersonPhone" name="contactPersonPhone" required>
        </mat-form-field>
    </mat-card>
</div>

<button mat-raised-button color="accent" class="submit-button" type="submit" (click)="onSubmit()">Submit</button>
}