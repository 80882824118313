import { __extends as t } from "tslib";
import { STORE_KEY as n, STORE_SUBKEY_IS_IN_CLIENT as o } from "@liff/consts";
import { isLIFFBrowser as e, isLINEBrowser as r } from "@liff/util";
import { LiffModule as i } from "@liff/use";
var c = null;
function u() {
  return null === c && (c = e() || r() && /[#|&]access_token=/.test(location.hash) || "1" === sessionStorage.getItem("".concat(n, ":").concat(o)), sessionStorage.setItem("".concat(n, ":").concat(o), c ? "1" : "0")), !!c;
}
function l() {
  c = null;
}
var f = function (n) {
  function o() {
    return null !== n && n.apply(this, arguments) || this;
  }
  return t(o, n), Object.defineProperty(o.prototype, "name", {
    get: function () {
      return "isInClient";
    },
    enumerable: !1,
    configurable: !0
  }), o.prototype.install = function () {
    return function () {
      return u();
    };
  }, o;
}(i);
export { f as IsInClientModule, l as _cleanupCachedIsInClient, u as isInClient };