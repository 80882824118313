import { __extends as t } from "tslib";
import { LiffModule as n } from "@liff/use";
function r() {
  return "2.23.2";
}
var e = function (n) {
  function r() {
    return null !== n && n.apply(this, arguments) || this;
  }
  return t(r, n), Object.defineProperty(r.prototype, "name", {
    get: function () {
      return "getVersion";
    },
    enumerable: !1,
    configurable: !0
  }), r.prototype.install = function () {
    return function () {
      return "2.23.2";
    };
  }, r;
}(n);
export { e as GetVersionModule, r as getVersion };