import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { LIFFExtendedProfile } from '../../interfaces/liff-extended-profile';
import { ReplaySubject, takeUntil } from 'rxjs';
import { LiffService } from '../../services/liff.service';
import { EnvService } from '../../services/env.service';
import { CommonModule } from '@angular/common';
import { OnboardService } from '../../services/onboard.service';
import { OnboardingRequest } from '../../interfaces/onboarding-request';
import { Router } from '@angular/router';
import { CustomerService } from '../../services/customer.service';
import { Customer } from '../../interfaces/customer';

@Component({
  selector: 'app-onboard',
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatToolbarModule,
    MatIconModule
  ],
  templateUrl: './onboard.component.html',
  styleUrl: './onboard.component.scss'
})
export class OnboardComponent implements OnInit {
  public profile!: LIFFExtendedProfile;

  restaurantName: string = '';
  contactPersonName: string = '';
  contactPersonPhone: string = '';

  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    private liffService: LiffService,
    private env: EnvService,
    private onboardService: OnboardService,
    private customerService: CustomerService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.liffService.init$(this.env.config.liffApps['onboard'])
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (profile: LIFFExtendedProfile) => {
          this.profile = profile;
          this.contactPersonName = profile.displayName;
          this.goToProfilePageIfAlreadyBeCustomer();
        },
        error: (e) => {
          if (!this.liffService.isLoggedIn()) {
            this.liffService.login('/onboard');
          }
        },
        complete: () => {
        }
      })
  }

  goToProfilePageIfAlreadyBeCustomer() {
    this.customerService.get(this.profile.accessToken!)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (customer: Customer) => {
          if(!!customer) {
            this.router.navigate(['customer/profile'], { state: { customer: customer } });
          }
        },
        error: (e) => {            
          console.log(e);
        },
        complete: () => {
        }
      });
  }

  onSubmit() {
    if(this.profile.accessToken && this.restaurantName && this.contactPersonName && this.contactPersonPhone) {
      const request = {
        name: this.contactPersonName,
        phoneNo: this.contactPersonPhone,
        restaurantName: this.restaurantName,
      } as OnboardingRequest;
      this.onboardService.onboard(this.profile.accessToken!, request)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            this.router.navigate(['/products'])
          },
          error: (e) => {            
          },
          complete: () => {
          }
        });
    }
  }
}
