import { __extends as r } from "tslib";
import { fetch as t, getEndPoint as e } from "@liff/server-api";
import { LiffModule as i } from "@liff/use";
import { attachChecker as n } from "@liff/permission";
function o() {
  return t(e("friendship"));
}
var f = function (t) {
  function e() {
    return null !== t && t.apply(this, arguments) || this;
  }
  return r(e, t), Object.defineProperty(e.prototype, "name", {
    get: function () {
      return "getFriendship";
    },
    enumerable: !1,
    configurable: !0
  }), e.prototype.install = function () {
    return n(o, "profile");
  }, e;
}(i);
export { f as GetFriendshipModule, o as getFriendship };