import { __awaiter as t, __generator as n, __values as e, __spreadArray as r, __read as i, __extends as o } from "tslib";
import { PERMISSION_NAMES as s, INVALID_ARGUMENT as c, UNAUTHORIZED as a, FORBIDDEN as u, UNKNOWN as f } from "@liff/consts";
import { createLiffError as l, extractChannelIdFromLiffId as h } from "@liff/util";
import { getContext as p, getAccessToken as d, getConfig as v, setAccessToken as m } from "@liff/store";
import { verifyAccessToken as w, fetch as b, getEndPoint as y } from "@liff/server-api";
import { isInClient as g } from "@liff/is-in-client";
import { subWindow as C } from "@liff/sub-window";
import { isApiAvailable as j } from "@liff/is-api-available";
import { LiffModule as I } from "@liff/use";
function k(r) {
  return t(this, void 0, void 0, function () {
    var t, i, o, u, f, h, v;
    return n(this, function (n) {
      switch (n.label) {
        case 0:
          return function (t) {
            if (!s.includes(t)) throw l(c, "Unexpected permission name.");
            var n = p();
            return !!(null == n ? void 0 : n.scope.includes(t));
          }(r) ? (t = d()) ? [4, w(t)] : [3, 2] : [2, {
            state: "unavailable"
          }];
        case 1:
          i = n.sent(), o = unescape(i.scope).split(" ");
          try {
            for (u = e(o), f = u.next(); !f.done; f = u.next()) if (f.value.includes(r)) return [2, {
              state: "granted"
            }];
          } catch (m) {
            h = {
              error: m
            };
          } finally {
            try {
              f && !f.done && (v = u.return) && v.call(u);
            } finally {
              if (h) throw h.error;
            }
          }
          return [2, {
            state: "prompt"
          }];
        case 2:
          throw l(a, "LiffId is not found.");
      }
    });
  });
}
function A() {
  var t,
    n,
    e = p();
  return !!e && "square_chat" !== e.type && (j("skipChannelVerificationScreen") || !g() && (null === (n = null === (t = e.availability) || void 0 === t ? void 0 : t.skipChannelVerificationScreen) || void 0 === n ? void 0 : n.permission));
}
function S() {
  var t = v().liffId;
  if (t) return b("".concat(y("unauthorizedPermissions"), "?liffId=").concat(t), {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer ".concat(d())
    }
  });
  throw l(a, "liffId is required");
}
var T,
  q = C.on,
  P = C.off,
  x = C.open,
  E = function () {
    function e(e, r) {
      var i = this;
      this.onSubmit = function (e) {
        var r = e.newAccessToken,
          o = e.ICA_ERROR;
        return t(i, void 0, void 0, function () {
          return n(this, function (t) {
            return r ? this.resolve({
              newAccessToken: r
            }) : o && this.reject(l(f, o)), this.teardown(), [2];
          });
        });
      }, this.onClose = function () {
        return t(i, void 0, void 0, function () {
          return n(this, function (t) {
            return this.reject(l(a, "user didn't allow the agreement")), this.teardown(), [2];
          });
        });
      }, this.onCancel = function () {
        return t(i, void 0, void 0, function () {
          return n(this, function (t) {
            return this.reject(l(a, "user didn't allow the agreement")), this.teardown(), [2];
          });
        });
      }, this.onError = function (e) {
        return t(i, void 0, void 0, function () {
          return n(this, function (t) {
            return this.reject(e), this.teardown(), [2];
          });
        });
      }, this.resolve = e, this.reject = r, this.setup();
    }
    return e.prototype.setup = function () {
      q("submit", this.onSubmit), q("close", this.onClose), q("cancel", this.onCancel), q("error", this.onError);
    }, e.prototype.teardown = function () {
      P("submit", this.onSubmit), P("close", this.onClose), P("cancel", this.onCancel), P("error", this.onError), T = void 0;
    }, e.prototype.open = function (t) {
      var n = v().liffId;
      n ? x({
        url: "".concat("https://liff.line.me/1656032314-Xgrw5Pmk"),
        appData: {
          liffId: n,
          channelId: h(n),
          accessToken: d()
        },
        onBeforeTransition: t
      }).catch(this.reject) : this.reject(l(a, "liffId is required"));
    }, e;
  }();
function _() {
  return t(this, void 0, void 0, function () {
    var e,
      r,
      i = this;
    return n(this, function (o) {
      switch (o.label) {
        case 0:
          if (!A()) throw l(u, "SkipChannelVerificationScreen is unavailable.");
          return T && T.teardown(), e = function () {
            return t(i, void 0, void 0, function () {
              var t;
              return n(this, function (n) {
                switch (n.label) {
                  case 0:
                    return [4, S()];
                  case 1:
                    if (t = n.sent(), (g() ? t : t.filter(function (t) {
                      return "chat_message.write" !== t;
                    })).length <= 0) throw l(u, "All permissions have already been approved.");
                    return [2];
                }
              });
            });
          }, [4, new Promise(function (t, n) {
            (T = new E(t, n)).open(e);
          })];
        case 1:
          return r = o.sent().newAccessToken, m(r), [2];
      }
    });
  });
}
function R(e, o) {
  var s = this;
  return function () {
    for (var c = [], a = 0; a < arguments.length; a++) c[a] = arguments[a];
    return t(s, void 0, void 0, function () {
      var t, s, a;
      return n(this, function (n) {
        switch (n.label) {
          case 0:
            return t = (c.length > 0 ? c[c.length - 1] : {}).ignorePermissionCheck, s = void 0 !== t && t, [4, k(o)];
          case 1:
            if ("unavailable" !== (a = n.sent().state)) return [3, 2];
            throw l(u, "The permission is not in LIFF app scope.");
          case 2:
            return "prompt" !== a || !A() || s || !g() && "chat_message.write" === o ? [3, 4] : [4, _()];
          case 3:
            return n.sent(), [3, 5];
          case 4:
            s && c.pop(), n.label = 5;
          case 5:
            return [4, e.apply(void 0, r([], i(c), !1))];
          case 6:
            return [2, n.sent()];
        }
      });
    });
  };
}
var V = function (t) {
    function n() {
      return null !== t && t.apply(this, arguments) || this;
    }
    return o(n, t), Object.defineProperty(n.prototype, "name", {
      get: function () {
        return "permission";
      },
      enumerable: !1,
      configurable: !0
    }), n.prototype.install = function () {
      return {
        query: k,
        requestAll: _
      };
    }, n;
  }(I),
  z = new V();
export { V as PermissionModule, R as attachChecker, z as module };