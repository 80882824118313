import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvService {
  public config!: EnvConfiguration;

  constructor(private http: HttpClient) { }

  load(): Observable<EnvConfiguration> {
    return this.http.get<EnvConfiguration>(environment.configUrl).pipe(
      tap(config => {
        this.config = config;
      })
    );
  }
}

export interface EnvConfiguration {
  env: string;
  apiUrl: string;
  liffChannelId: string;
  liffApps: { [key: string]: string; };
  redirectTo: string;
  homeUrl: string;
  quickbook: QuickBookConfig;
}

export interface QuickBookConfig {
  authEndpoint: string;
  clientId: string;
  responseType: string;
  scope: string;
  redirectUri: string;
  state: string;
}