import { __extends as o } from "tslib";
import { compareVersion as i } from "@liff/util";
import { getOS as n } from "@liff/get-os";
import { getLineVersion as e } from "@liff/get-line-version";
import { call as t } from "@liff/native-bridge";
import { LiffModule as f } from "@liff/use";
function r() {
  var o = e();
  null !== o && ("ios" === n() && i(o, "9.19") >= 0 || "android" === n() && i(o, "11.6.0") >= 0) ? location.href = "liff://close" : window._liff && window._liff.postMessage ? null !== o && i(o, "10.15.0") >= 0 ? "ios" === n() ? window._liff.postMessage("closeWindow", "") : window._liff.postMessage("closeWindow", "", "", "") : t("closeWindow") : window.close();
}
var l = function (i) {
  function n() {
    return null !== i && i.apply(this, arguments) || this;
  }
  return o(n, i), Object.defineProperty(n.prototype, "name", {
    get: function () {
      return "closeWindow";
    },
    enumerable: !1,
    configurable: !0
  }), n.prototype.install = function () {
    return function () {
      return r();
    };
  }, n;
}(f);
export { l as CloseWindowModule, r as closeWindow };