@if (profile) {
    <mat-toolbar color="primary">
        <span>Order Summary</span>
        <span class="spacer"></span>
        <div class="profile-picture-container">
            <div class="profile-picture" [ngStyle]="{'background-image': 'url(' + profile.pictureUrl + ')'}"></div>
        </div>
    </mat-toolbar>

    <div *ngIf="products.length > 0" class="product-list">
        <mat-list>
            @for (product of products; track product) {
            <mat-list-item class="product-details">
                <span matListItemTitle>{{ product.name }}</span>
                <span matListItemLine>Price: {{ product.price | currency }}</span>
                <span matListItemLine>Quantity: {{ product.quantity }}</span>
                <span matListItemLine>Total: {{ product.price * product.quantity | currency }}</span>
            </mat-list-item>
            <mat-divider></mat-divider>
            }
        </mat-list>

        <mat-list>
            <mat-list-item>
                <p><strong>Grand Total: {{ getTotal() | currency }}</strong></p>
            </mat-list-item>
        </mat-list>
    </div>

    <div *ngIf="products.length === 0">
        <p>No products selected.</p>
    </div>

    <button mat-raised-button color="accent" class="confirm-button" (click)="confirmOrder()">Confirm</button>
}