import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvService } from '../env.service';
import { Order } from '../../interfaces/order';

@Injectable({
  providedIn: 'root'
})
export class AdminOrderService {

  constructor(private http: HttpClient, private env: EnvService) { }

  getAll(accessToken: string): Observable<Order[]> {
    const headers: { [header: string]: string } = this.getHttpHeaders(accessToken);

    const url = `${this.env.config.apiUrl}/admin/orders`;
    return this.http.get<Order[]>(url, { headers });
  }

  createInvoice(accessToken: string, orderId: number): Observable<Order> {
    const headers: { [header: string]: string } = this.getHttpHeaders(accessToken);
    const url = `${this.env.config.apiUrl}/admin/orders/${orderId}/invoice`;
    return this.http.post<Order>(url, null, { headers });
  }

  private getHttpHeaders(accessToken: string): { [header: string]: string } {
    return {
      'X-Access-Token': accessToken
    };
  }
}
