import { Injectable } from '@angular/core';
import { Subject, Observable, takeUntil } from 'rxjs';
import { EnvService } from './env.service';

@Injectable({
  providedIn: 'root'
})
export class AppInitializerService {
  private load$: Subject<boolean> = new Subject<boolean>();

  constructor(private env: EnvService) { }

  load(): Observable<any> {
    this.env.load()
      .pipe(
        takeUntil(this.load$)
      )
      .subscribe(() => {
        this.load$.next(true);
        this.load$.complete();
      });

    return this.load$;
  }
}
