import { __spreadArray as n, __read as t, __extends as r, __awaiter as e, __generator as i } from "tslib";
import { INVALID_ARGUMENT as a, CREDENTIAL_KEYS as o, INIT_FAILED as s, INVALID_CONFIG as u, PERMANENT_LINK_ORIGIN as c, PERMANENT_LINK_ORIGIN_MINI as l } from "@liff/consts";
import { createLiffError as f } from "@liff/util";
import { getContext as p, getConfig as h } from "@liff/store";
import { LiffModule as m } from "@liff/use";
var d = /([\x90\x9D\x81\x8D\x8F<"{|}>\\^`“›„•‚ŽŠ…’—ž–‘”‡™‰ŒšŸ‹œ†¥¿©áÄýÍÎðô]|\n|.*#.*#|%(?![0-9A-Fa-f]{2})[^%]{0,2})/,
  g = function (n) {
    if (d.test(n)) throw f(a, "invalid URL.");
    var t = new URL(n),
      r = t.username,
      e = t.password,
      i = t.hash,
      o = t.search;
    return {
      username: r,
      password: e,
      pathname: t.pathname,
      hash: i,
      origin: t.origin,
      search: o.replace(/(?:^|;|&)([^;&]*)[;&]*(?=($|;|&))/g, "$1&").replace(/&+$/g, "").replace(/^\?&/, "?")
    };
  },
  v = function (n) {
    return n.substring(1).split("&").filter(function (n) {
      return !/^liff\./.test(n) && Boolean(n);
    });
  },
  w = function (n, t) {
    var r = n.substring(t.length);
    return "/" === r ? "" : (r.length > 0 && "/" !== r[0] && (r = "/" + r), r);
  },
  x = function (r, e) {
    var i,
      a,
      o,
      s = function (r, e) {
        for (var i = n([], t(r), !1), a = 0; a < e.length; a++) {
          var o = e[a],
            s = i.indexOf(o);
          s > -1 && i.splice(s, 1);
        }
        return i;
      }((i = v(r).join("&"), a = new URLSearchParams(i).toString().split("&"), o = i.split("&"), a.map(function (n, t) {
        return n.endsWith("=") && !o[t].endsWith("=") ? n.slice(0, -1) : n;
      })), v(e)).join("&");
    return "" !== s ? "?".concat(s) : "";
  },
  U = function (n) {
    var t = new RegExp("^".concat(o.join("|"))),
      r = n.substring(1).split("&").filter(function (n) {
        return !t.test(n) && Boolean(n);
      }).join("&");
    return r ? "#".concat(r) : "";
  },
  b = function (n, t) {
    return 0 === t.indexOf(n) && (n.endsWith("/") && (n = n.substring(0, n.length - 1)), void 0 === t[n.length] || "/" === t[n.length]);
  },
  L = function (n, t) {
    var r = g(n),
      e = new URL(t);
    if (r.username !== e.username || r.password !== e.password) throw f(a, "invalid URL.");
    if (e.origin !== r.origin || !b(e.pathname, r.pathname)) throw f(a, "invalid URL.");
  },
  P = function (n) {
    function t() {
      var t = null !== n && n.apply(this, arguments) || this;
      return t.extraParams = "", t.getAndValidateContext = function () {
        var n = p();
        if (!n) throw f(s, "Could not get Context from server.");
        if (!n.endpointUrl) throw f(s, "Could not get endpointUrl from server.");
        if (!n.permanentLinkPattern) throw f(s, "Could not get permanentLinkPattern from server.");
        return n;
      }, t.createUrl = function () {
        var n = t.getAndValidateContext(),
          r = window.location,
          e = r.pathname,
          i = r.search,
          a = r.hash,
          s = r.origin,
          l = new URL(n.endpointUrl);
        if (l.origin !== s || !b(l.pathname, e)) throw f(u, "Current page is not under entrypoint.");
        var p = e.substring(l.pathname.length);
        p.length > 0 && "/" !== p[0] && (p = "/" + p);
        var m = new RegExp("^".concat(o.join("|"))),
          d = a.substring(1).split("&").filter(function (n) {
            return !m.test(n) && Boolean(n);
          }).join("&"),
          g = d === l.hash.substring(1) ? "" : d,
          v = function (n) {
            return n.substring(1).split("&").filter(function (n) {
              return !/liff\.state/.test(n) && Boolean(n);
            });
          },
          w = v(i),
          x = v(l.search);
        t.extraParams && w.push(t.extraParams);
        for (var U = 0; U < x.length; U++) {
          var L = x[U],
            P = w.indexOf(L);
          P > -1 && w.splice(P, 1);
        }
        var y = w.join("&"),
          R = "".concat(p).concat("" !== y ? "?".concat(y) : "").concat(g ? "#".concat(g) : "");
        return "".concat(c).concat(h().liffId).concat(R);
      }, t.createUrlBy = function (n) {
        return e(t, void 0, void 0, function () {
          var t, r, e, a, o, u;
          return i(this, function (i) {
            if (!(t = h().liffId)) throw f(s, "Should run after liff init.");
            return r = this.getAndValidateContext(), L(n, r.endpointUrl), e = g(n), a = new URL(r.endpointUrl), o = r.miniDomainAllowed ? l : c, u = r.miniDomainAllowed && r.miniAppId ? r.miniAppId : t, [2, o.concat(u, w(e.pathname, a.pathname), x(e.search, a.search), U(e.hash))];
          });
        });
      }, t.setExtraQueryParam = function (n) {
        t.extraParams = n;
      }, t.install = function () {
        return {
          createUrl: t.createUrl,
          createUrlBy: t.createUrlBy,
          setExtraQueryParam: t.setExtraQueryParam
        };
      }, t;
    }
    return r(t, n), Object.defineProperty(t.prototype, "name", {
      get: function () {
        return "permanentLink";
      },
      enumerable: !1,
      configurable: !0
    }), t;
  }(m),
  y = new P();
export { P as PermanentLinkModule, y as module, L as validatePermalinkGeneratability };