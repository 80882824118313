@if (profile) {
    <mat-toolbar color="primary">
        <span>Product List</span>
        <span class="spacer"></span>
        <div class="profile-picture-container">
            <div class="profile-picture" [ngStyle]="{'background-image': 'url(' + profile.pictureUrl + ')'}"></div>
        </div>
    </mat-toolbar>
    
    <div class="product-list">
        <mat-card *ngFor="let product of products" class="product-card">
            <mat-card-title class="product-title">{{ product.name }}</mat-card-title>
            <mat-card-content>
                <div>Price: {{ product.price | currency }}</div>
                <div class="quantity-controls">
                    <div>Quantity: </div>
                    <button mat-icon-button (click)="decreaseQuantity(product)">
                        <mat-icon>remove</mat-icon>
                    </button>
                    <div>{{ product.quantity }}</div>
                    <button mat-icon-button (click)="increaseQuantity(product)">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <button mat-raised-button color="accent" class="checkout-button" (click)="checkout()">Checkout</button>
}