<mat-toolbar color="primary">
    <span>Orders</span>
</mat-toolbar>
  
<div class="order-list-container">
    @if (orders.length > 0) {
    <mat-list>
        @for (order of orders; track order) {
        <mat-list-item (click)="viewOrderDetail(order)">
            <span matListItemTitle>Order #{{ order.id }}</span>
            <span matListItemLine>Total amount {{ order.totalAmount }}</span>
            <span matListItemLine>Status {{ order.status }}</span>
        </mat-list-item>
        <mat-divider></mat-divider>
        }
    </mat-list>
    }
    @if (orders.length === 0) {
    <div *ngIf="orders.length === 0">
        <p>No orders found.</p>
    </div>
    }
</div>
  