import { Component } from '@angular/core';
import { QuickbookService } from '../../services/admin/quickbook.service';

@Component({
  selector: 'app-quickbook-connect',
  templateUrl: './quickbook-connect.component.html',
  styleUrl: './quickbook-connect.component.scss'
})
export class QuickbookConnectComponent {
  constructor(private quickbookService: QuickbookService) {}

  goLogin() {
    this.quickbookService.goLogin();
  }  
}
