import { getVersion as t } from "@liff/get-version";
import { compareVersion as i } from "@liff/util";
import { validators as n } from "@liff/is-api-available";
var e = function () {
    function t() {}
    return t.prototype.invoke = function (t) {
      var i = n[t];
      return !!i && (i(), !0);
    }, t;
  }(),
  r = function () {
    function t(t) {
      this.liff = t;
    }
    return t.prototype.invoke = function (t) {
      return this.liff.checkFeature(t);
    }, t;
  }(),
  o = function () {
    function n(o) {
      i(t(), n.SDK_VERSION_SUPPORTING_NEW) >= 0 ? this.impl = new e() : this.impl = new r(o);
    }
    return Object.defineProperty(n, "SDK_VERSION_SUPPORTING_NEW", {
      get: function () {
        return "2.11.0";
      },
      enumerable: !1,
      configurable: !0
    }), n.prototype.invoke = function (t) {
      return this.impl.invoke(t);
    }, n;
  }();
export { o as CheckAvailability };