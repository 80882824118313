import { __extends as o, __awaiter as t, __generator as n } from "tslib";
import { subWindow as e } from "@liff/sub-window";
import { LiffModule as r } from "@liff/use";
import { validators as i } from "@liff/is-api-available";
var s,
  u = function () {
    function o(o, t) {
      var n = this;
      this.resolve = o, this.reject = t, this.onSubmit = function (o) {
        var t = o.message;
        n.resolve({
          value: t
        }), n.destroy();
      }, this.onClose = function () {
        n.resolve({
          value: null
        }), n.destroy();
      }, this.onCancel = function () {
        n.resolve({
          value: null
        }), n.destroy();
      }, this.onError = function (o) {
        n.reject(o), n.destroy();
      }, this.start();
    }
    return o.prototype.start = function () {
      e.on("submit", this.onSubmit), e.on("close", this.onClose), e.on("cancel", this.onCancel), e.on("error", this.onError);
    }, o.prototype.destroy = function () {
      e.off("submit", this.onSubmit), e.off("close", this.onClose), e.off("cancel", this.onCancel), e.off("error", this.onError), s = void 0;
    }, o;
  }();
function f() {
  return t(this, void 0, void 0, function () {
    return n(this, function (o) {
      return i.scanCodeV2(), s && s.destroy(), [2, new Promise(function (o, t) {
        s = new u(o, t), e.open({
          url: "https://liff.line.me/1656359117-jxmx5e11"
        }).catch(function (o) {
          null == s || s.destroy(), t(o);
        });
      })];
    });
  });
}
var l = function (t) {
    function n() {
      return null !== t && t.apply(this, arguments) || this;
    }
    return o(n, t), Object.defineProperty(n.prototype, "name", {
      get: function () {
        return "scanCodeV2";
      },
      enumerable: !1,
      configurable: !0
    }), n.prototype.install = function () {
      return f;
    }, n;
  }(r),
  c = new l();
export { l as ScanCodeV2Module, c as module };