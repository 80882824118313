import { __awaiter as t, __generator as r, __extends as n } from "tslib";
import { LiffModule as e } from "@liff/use";
import { INVALID_ARGUMENT as i, FORBIDDEN as o, INIT_FAILED as u } from "@liff/consts";
import { getOS as c } from "@liff/get-os";
import { getContext as s, getConfig as f } from "@liff/store";
import { isLineCustomUrlScheme as l, createLiffError as a, qs as p, extractLiffId as m } from "@liff/util";
import { validators as h } from "@liff/is-api-available";
import { isInClient as d } from "@liff/is-in-client";
import { openWindow as v } from "@liff/open-window";
import { getEndPoint as b, fetch as w } from "@liff/server-api";
import { validatePermalinkGeneratability as y } from "@liff/permanent-link";
function S(n, e) {
  return t(this, void 0, void 0, function () {
    var t, i;
    return r(this, function (r) {
      switch (r.label) {
        case 0:
          return t = b("createShortcutOnHomeScreen"), (i = new URL(t)).searchParams.append("liffId", n), i.searchParams.append("url", e.url), e.description && i.searchParams.append("description", e.description), [4, w(i.toString())];
        case 1:
          return [2, r.sent().shortcutPageUrl];
      }
    });
  });
}
function g(t) {
  if (l(t)) throw a(i, "LINE URL scheme are not supported in the current environment.");
}
function I(n, e) {
  return t(this, void 0, void 0, function () {
    var t;
    return r(this, function (r) {
      switch (r.label) {
        case 0:
          return d() ? [2] : [4, new Promise(function (t) {
            setTimeout(t, 1e3);
          })];
        case 1:
          return r.sent(), "hidden" === document.visibilityState ? [2] : (g(e.url), [4, S(n, e)]);
        case 2:
          return t = r.sent(), v({
            url: t,
            external: !0
          }), [2];
      }
    });
  });
}
function O(n, e) {
  return t(this, void 0, void 0, function () {
    var t, i;
    return r(this, function (r) {
      switch (r.label) {
        case 0:
          return t = {
            liffId: n,
            targetUrl: e.url,
            description: e.description
          }, i = "".concat("line://shortcut/liff", "?").concat(p.stringify(t)), location.href = i, [4, I(n, e)];
        case 1:
          return r.sent(), [2];
      }
    });
  });
}
function P(n, e) {
  return t(this, void 0, void 0, function () {
    var t;
    return r(this, function (r) {
      switch (r.label) {
        case 0:
          return g(e.url), [4, S(n, e)];
        case 1:
          return t = r.sent(), v({
            url: t,
            external: !0
          }), [2];
      }
    });
  });
}
function H(t) {
  var r;
  if (void 0 === t.url || null === t.url || "" === t.url) throw a(i, "no proper argument");
  var n = s();
  if (!(null === (r = null == n ? void 0 : n.availability.addToHomeLineScheme) || void 0 === r ? void 0 : r.permission)) {
    if (l(t.url)) throw a(o, "No permission to specify line schema in url.");
    if (t.description) throw a(o, "No permission to specify description.");
  }
  if (!l(t.url)) {
    if (!n) throw a(o, "Could not get Context from server.");
    n.liffId !== m(t.url) && y(t.url, n.endpointUrl);
  }
}
function L(n) {
  return t(this, void 0, void 0, function () {
    return r(this, function (t) {
      switch (t.label) {
        case 0:
          return H(n), h.internalCreateShortcutOnHomeScreen(), [4, x(n)];
        case 1:
          return t.sent(), [2];
      }
    });
  });
}
function U(n) {
  return t(this, void 0, void 0, function () {
    return r(this, function (t) {
      switch (t.label) {
        case 0:
          return H(n), h.createShortcutOnHomeScreen(), [4, x(n)];
        case 1:
          return t.sent(), [2];
      }
    });
  });
}
function x(n) {
  return t(this, void 0, void 0, function () {
    var t;
    return r(this, function (r) {
      switch (r.label) {
        case 0:
          if (!(t = f().liffId)) throw a(u, "Invalid LIFF ID.");
          return "ios" === c() ? [4, P(t, n)] : [3, 2];
        case 1:
          return r.sent(), [3, 4];
        case 2:
          return [4, O(t, n)];
        case 3:
          r.sent(), r.label = 4;
        case 4:
          return [2];
      }
    });
  });
}
var C = function (t) {
    function r() {
      return null !== t && t.apply(this, arguments) || this;
    }
    return n(r, t), Object.defineProperty(r.prototype, "name", {
      get: function () {
        return "createShortcutOnHomeScreen";
      },
      enumerable: !1,
      configurable: !0
    }), r.prototype.install = function () {
      return function (t) {
        return U(t);
      };
    }, r;
  }(e),
  N = function (t) {
    function r() {
      return null !== t && t.apply(this, arguments) || this;
    }
    return n(r, t), Object.defineProperty(r.prototype, "name", {
      get: function () {
        return "internalCreateShortcutOnHomeScreen";
      },
      enumerable: !1,
      configurable: !0
    }), r.prototype.install = function () {
      return function (t) {
        return L(t);
      };
    }, r;
  }(e);
export { C as CreateShortcutOnHomeScreenModule, N as InternalCreateShortcutOnHomeScreenModule, U as createShortcutOnHomeScreen, L as internalCreateShortcutOnHomeScreen };