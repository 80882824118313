@if(successPageData) {
<div class="page-container">
    <div class="page-content">
        <div class="success-group">
            <img class="icon" src="assets/images/icons/success_cycle.svg">
            <p class="desciption">Success</p>
        </div>
        <button class="button-secondary" (click)="navigateToNextPage(successPageData.naxtPath)">{{
            successPageData.buttonName }}</button>
    </div>
</div>
}